import { ResponseInterface } from './types';
import { Industry } from './industry.model';
import { AppUserSettings } from './app-user-settings.model';

export class User {
  constructor();
  constructor(
    userId: number,
    createdById: number,
    lastModifiedById: number,
    organisationId: number,
    organisationName: string,
    userGuid: string,
    firstName: string,
    middleName: string,
    lastName: string,
    email: string,
    passwordHash: string,
    comments: string,
    lockedOut: boolean,
    uiSettings: string,
    suppressEmail: boolean,
    crmLink: string,
    creationDate: Date,
    lastModifiedDate: Date,
    lastLoginDate: Date,
    previousLastLoginDate: Date,
    termsAcceptedDate: Date,
    industries: Industry[],
    applicationSettings: AppUserSettings[]
  );
  constructor(
    public userId?: number,
    public createdById?: number,
    public lastModifiedById?: number,
    public organisationId?: number,
    public organisationName?: string,
    public userGuid?: string,
    public firstName?: string,
    public middleName?: string,
    public lastName?: string,
    public email?: string,
    public passwordHash?: string,
    public comments?: string,
    public lockedOut?: boolean,
    public uiSettings?: string,
    public suppressEmail?: boolean,
    public crmLink?: string,
    public creationDate?: Date,
    public lastModifiedDate?: Date,
    public lastLoginDate?: Date,
    public previousLastLoginDate?: Date,
    public termsAcceptedDate?: Date,
    public industries?: Industry[],
    public applicationSettings?: AppUserSettings[]
  ) {}

  static adaptUser(item: any): User {
    return new User(
      item.userId,
      item.createdById,
      item.lastModifiedById,
      item.organisationId,
      item.organisationName,
      item.userGuid,
      item.firstName,
      item.middleName,
      item.lastName,
      item.email,
      item.passwordHash,
      item.comments,
      item.lockedOut,
      item.uiSettings,
      item.suppressEmail,
      item.crmLink,
      new Date(item.creationDate),
      new Date(item.lastModifiedDate),
      new Date(item.lastLoginDate),
      new Date(item.previousLastLoginDate),
      new Date(item.termsAcceptedDate),
      item.industries.map(Industry.adapt),
      item.applicationSettings
        ? item.applicationSettings.map(AppUserSettings.adapt)
        : null
    );
  }

  static adaptUserList(item: any): User {
    return new User(
      item.userId,
      item.createdById,
      item.lastModifiedById,
      item.organisationId,
      item.organisationName,
      item.userGuid,
      item.firstName,
      item.middleName,
      item.lastName,
      item.email,
      item.passwordHash,
      item.comments,
      item.lockedOut,
      item.uiSettings,
      item.suppressEmail,
      item.crmLink,
      new Date(item.creationDate),
      new Date(item.lastModifiedDate),
      new Date(item.lastLoginDate),
      new Date(item.previousLastLoginDate),
      new Date(item.termsAcceptedDate),
      item.industries.map(Industry.adapt),
      undefined
    );
  }
}

export class UsersData {
  constructor(public users: User[]) {}
}

export class UsersResponse implements ResponseInterface {
  status = '';
  data: UsersData;
  constructor() {}
}

export class UserResponse implements ResponseInterface {
  status = '';
  data: User;
  constructor() {}
}

export class UserRoles {
  constructor(public userId?: number, public roles?: string[]) {}

  static adapt(item: any): UserRoles {
    return new UserRoles(item.userId, item.roles);
  }
}

export class UserRolesResponse implements ResponseInterface {
  status = '';
  data: UserRoles;
  constructor() {}
}
