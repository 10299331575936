<!-- Placeholder to adjust vertical offset of the mat-option elements -->
<input matInput class="idl-filter-select-input idl-filter-select-hidden" />

<!-- Note: the  mat-datepicker-content mat-tab-header are needed to inherit the material theme colors, see PR #22 -->
<div #innerSelectFilter class="idl-filter-select-inner mat-typography mat-datepicker-content mat-tab-header"
  [ngClass]="{'idl-filter-select-inner-multiple': matSelect.multiple}">
  <input matInput class="idl-filter-select-input" autocomplete="off" [type]="type" [value]="value" #filterSelectInput
    (keydown)="_handleKeydown($event)" (input)="onInputChange($event.target.value)" (blur)="onBlur($event.target.value)"
    [placeholder]="placeholderLabel" [attr.aria-label]="ariaLabel" />
  <mat-spinner *ngIf="searching" class="idl-filter-select-spinner" diameter="16"></mat-spinner>

  <button mat-button *ngIf="value && !searching" mat-icon-button aria-label="Clear" (click)="_reset(true)"
    class="idl-filter-select-clear">
    <ng-content *ngIf="clearIcon; else defaultIcon" select="[idlFilterSelectClear]"></ng-content>
    <ng-template #defaultIcon>
      <mat-icon>close</mat-icon>
    </ng-template>
  </button>
</div>

<div *ngIf="_noEntriesFound()" class="idl-filter-select-no-entries-found">
  {{noEntriesFoundLabel}}
</div>
