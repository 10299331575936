import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IndustriesResponse, Industry } from '../models/industry.model';
import {
  Organisation,
  OrganisationResponse,
  OrganisationsResponse
} from '../models/organistion.model';

@Injectable({
  providedIn: 'root'
})
export class OrganisationService {
  private baseUrl = 'api/organisations';

  constructor(private http: HttpClient) {}

  list(): Observable<Organisation[]> {
    const url = `${this.baseUrl}`;
    return this.http.get(url).pipe(
      // Adapt each item in the raw data array
      map((response: OrganisationsResponse) =>
        response.data.organisations.map(Organisation.adapt)
      )
    );
  }

  get(orgId: number): Observable<Organisation> {
    const url = `${this.baseUrl}/${orgId}`;
    return this.http.get(url).pipe(
      // Adapt each item in the raw data array
      map((response: OrganisationResponse) => Organisation.adapt(response.data))
    );
  }

  edit(org: Organisation): Observable<Organisation> {
    const url = `${this.baseUrl}`;
    return this.http.put(url, org).pipe(
      // Adapt each item in the raw data array
      map((response: OrganisationResponse) => Organisation.adapt(response.data))
    );
  }

  delete(orgId: number) {
    const url = `${this.baseUrl}/${orgId}`;
    return this.http.delete(url);
  }

  listindustries(orgId: number): Observable<Industry[]> {
    const url = `${this.baseUrl}/${orgId}/industries`;
    return this.http.get(url).pipe(
      // Adapt each item in the raw data array
      map((response: IndustriesResponse) =>
        response.data.industries.map(Industry.adapt)
      )
    );
  }

  addIndustry(orgId: number, industryId: number): Observable<Industry[]> {
    const url = `${this.baseUrl}/${orgId}/industries`;
    return this.http.put(url, { industryId: industryId }).pipe(
      // Adapt each item in the raw data array
      map((response: IndustriesResponse) =>
        response.data.industries.map(Industry.adapt)
      )
    );
  }

  deleteIndustry(orgId: number, industryId: number): Observable<Industry[]> {
    const url = `${this.baseUrl}/${orgId}/industries`;
    let params = new HttpParams();
    params = params.append('industry', industryId.toString());
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };

    return this.http.delete(url, options).pipe(
      // Adapt each item in the raw data array
      map((response: IndustriesResponse) =>
        response.data.industries.map(Industry.adapt)
      )
    );
  }
}
