import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { RoleGuardService } from './core/core.module';
import { UserEditPageComponent } from './pages/users/user-edit/user-edit-page.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'about',
    pathMatch: 'full'
  },
  {
    path: 'applications',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/applications/applications.module').then(
        m => m.ApplicationsModule
      )
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'industries',
    loadChildren: () =>
      import('./pages/industries/industries.module').then(
        m => m.IndustriesModule
      )
  },
  {
    path: 'licenses',
    loadChildren: () =>
      import('./pages/licenses/licenses.module').then(m => m.LicensesModule)
  },
  {
    path: 'logs',
    loadChildren: () =>
      import('./pages/logs/logs.module').then(m => m.LogsModule)
  },
  {
    path: 'organisations',
    loadChildren: () =>
      import('./pages/organisations/organisations.module').then(
        m => m.OrganisationsModule
      )
  },
  {
    path: 'profile',
    children: [
      {
        path: ':id',
        component: UserEditPageComponent,
        canActivate: [AuthGuard, RoleGuardService],
        data: {
          expectedRoles: ['Manager', 'Administrator', 'SuperUser'],
          addUser: false
        }
      },
      {
        path: '',
        component: UserEditPageComponent,
        canActivate: [AuthGuard],
        data: {
          addUser: false
        }
      }
    ]
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./pages/users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'about',
    loadChildren: () =>
      import('./features/about/about.module').then(m => m.AboutModule)
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./features/settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: '**',
    redirectTo: 'about'
  }
];

@NgModule({
  // useHash supports github.io demo page, remove in your app
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      useHash: false,
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
