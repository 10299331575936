import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appAvatar]'
})
export class AvatarDirective implements OnInit {
  @Input() set url(value: string) {
    this.updateAvatar(value);
  }

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    // if (this.el) {
    //   this.renderer.setAttribute(this.el.nativeElement, 'src', this.url)
    // }
  }

  updateAvatar(url: string): void {
    if (!url || !this.el.nativeElement) {
      return;
    }

    this.renderer.setAttribute(this.el.nativeElement, 'src', url);
  }
}
