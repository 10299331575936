import { ResponseInterface } from './types';

export class Industry {
  constructor();
  constructor(
    industryId: number,
    name: string,
    comments: string,
    createdById: number
  );
  constructor(
    public industryId?: number,
    public name?: string,
    public comments?: string,
    public createdById?: number
  ) {}

  static adapt(item: any): Industry {
    return new Industry(
      item.industryId,
      item.name,
      item.comments,
      item.createdById
    );
  }
}

export class IndustriesData {
  constructor(public industries: Industry[]) {}
}

export class IndustriesResponse implements ResponseInterface {
  status = '';
  data: IndustriesData;
  constructor() {}
}

export class IndustryResponse implements ResponseInterface {
  status = '';
  data: Industry;
  constructor() {}
}
