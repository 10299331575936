import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { concatMap, take } from 'rxjs/operators';
import { LoginComplete } from '~app/core/auth/store/actions/auth.actions';
import { AppState } from '~app/core/core.state';
import { ResponseInterface } from '~app/core/models/types';

@Injectable({
  providedIn: 'root'
})
export class AuthLocalService {
  // Create a local property for login status
  loggedIn: boolean = null;

  constructor(
    private _auth: AuthService,
    private router: Router,
    private http: HttpClient,
    private store: Store<AppState>
  ) {}

  localAuthSetup(redirectPath: string = null) {
    // This should only be called on app initialization
    // Check if user already has an active session with Auth0
    const checkAuth$ = this._auth.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          // If authenticated, return stream that emits user object and token
          return combineLatest([
            this._auth.getAccessTokenSilently(),
            this._auth.user$
          ]);
        }
        // If not authenticated, return stream that emits 'false'
        return of(loggedIn);
      })
    );

    const checkAuthSub = checkAuth$.subscribe((response: any[] | boolean) => {
      // If authenticated, response will be array of user object and token
      // If not authenticated, response will be 'false'
      // Set subjects appropriately
      if (response && response[0] != null && response[1] != null) {
        const token = response[0];
        const user = response[1];

        // console.log('redirect: ' + redirectPath);
        // can only do this after setting user and token so interceptor can add auth
        this.policyLogin()
          .pipe(take(1))
          .subscribe((res: ResponseInterface) => {
            this.store.dispatch(
              new LoginComplete(
                user,
                true,
                res.data.userId,
                res.data.roles,
                redirectPath
              )
            );
          });
        // this.store.dispatch(new LoginComplete(user, token, true, '', null));
      }
      this.loggedIn = !!response;
      // Clean up subscription
      if (!response) checkAuthSub.unsubscribe();
    });
  }

  // Informs policy server of user that has logged in, gets roles in return
  policyLogin() {
    return this.http.put('api/auth/loggedin', {});
  }
}
