<form #generateForm="ngForm" [formGroup]="generateLicenseForm">
  <mat-form-field *ngIf="license" appearance="fill" class="full-width">
    <mat-label>Application</mat-label>
    <mat-select required placeholder="Application" formControlName="selectedApplication"
      (ngModelChange)="onChangeApplication($event)" [compareWith]="compareApplication">
      <mat-option [value]="app" *ngFor="let app of applications">
        {{app.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" class="full-width">
    <mat-label>User</mat-label>
    <mat-select required placeholder="User" formControlName="selectedUser" #singleSelect [compareWith]="compareUser">
      <mat-option>
        <idl-filter-select formControlName="selectedUserFilter">
        </idl-filter-select>
      </mat-option>
      <mat-option [value]="user" *ngFor="let user of filteredUsers | async">
        {{user.fullName}} ({{user.email}})
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" class="full-width">
    <mat-label>License Type</mat-label>
    <mat-select required placeholder="License Type" formControlName="licenseType">
      <mat-option [value]="type.name" *ngFor="let type of types">
        {{type.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Expiry Date</mat-label>
    <input matInput [matDatepicker]="picker" placeholder="Expiry Date" formControlName="expiryDate">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <button type="button" mat-raised-button color="accent" (click)="AddAdditionalEmail()">Add Additional Email</button>

  <div formArrayName="additionalEmails">
  <mat-form-field *ngFor="let f of (generateLicenseForm.controls.additionalEmails)['controls'];let i=index" appearance="fill" class="full-width">
    <mat-label>Email</mat-label>
    <input matInput placeholder="Email" [formControlName]="i">
    <button mat-icon-button matSuffix (click)="DeleteAdditionalEmail(i)" [attr.aria-label]="'Remove Email'" [attr.aria-pressed]="delete">
      <mat-icon>delete</mat-icon>
    </button>
  </mat-form-field>
  </div>

  <mat-form-field appearance="fill" class="full-width" floatLabel="always">
    <mat-label>Metadata Set</mat-label>
    <mat-select placeholder="Use Default" formControlName="selectedSet" (ngModelChange)="onChangeSet($event)"
      [compareWith]="compareSet">
      <mat-option>Use Default</mat-option>
      <mat-option [value]="set" *ngFor="let set of (metadataSets$ | async)">
        {{set.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field *ngIf="generateLicenseForm.controls.selectedSet.value" appearance="fill" class="full-width">
    <mat-label>Set Description</mat-label>
    <textarea rows="5" readonly matInput placeholder="Description" formControlName="setDescription"></textarea>
  </mat-form-field>

  <div class="table-container">
    <idl-meta-data-values [dataSource]="dataSource" [overrideMetaData]="overrideMetaData"></idl-meta-data-values>
  </div>

  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Comments</mat-label>
    <textarea rows="5" matInput placeholder="Comments" formControlName="comments"></textarea>
  </mat-form-field>

</form>