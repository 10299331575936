import { ResponseInterface } from './types';

export class UserSummary {
  constructor(
    public userId?: number,
    public organisationName?: string,
    public fullName?: string,
    public email?: string
  ) {}

  static adapt(item: any): UserSummary {
    return new UserSummary(
      item.userId,
      item.organisationName,
      item.fullName,
      item.email
    );
  }
}

export class UsersSummaryData {
  constructor(public users: UserSummary[]) {}
}

export class UsersSummaryResponse implements ResponseInterface {
  status = '';
  data: UsersSummaryData;
  constructor() {}
}

export class UserSummaryResponse implements ResponseInterface {
  status = '';
  data: UserSummary;
  constructor() {}
}
