import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { CallbackComponent } from './components/callback.component';
import { LogoutPromptComponent } from './components/logout-prompt.component';
import { AuthLocalService } from './services/auth.service';
import { Login, Logout } from './store/actions/auth.actions';
import {
  selectIsAuthenticated,
  selectUserPicture
} from './store/auth.selectors';

export {
  AuthLocalService,
  selectIsAuthenticated,
  selectUserPicture,
  Login,
  Logout
};

@NgModule({
  declarations: [CallbackComponent, LogoutPromptComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    RouterModule

    // EffectsModule.forRoot([
    //   AuthEffects,
    // ]),
  ],
  providers: [
    // AuthService,
  ],
  exports: [LogoutPromptComponent],
  entryComponents: [CallbackComponent, LogoutPromptComponent]
})
export class AuthLocalModule {
  static forRoot(): ModuleWithProviders<AuthLocalModule> {
    return {
      ngModule: AuthLocalModule
    };
  }
}
