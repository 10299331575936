import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Version } from '~core/models/version.model';

export interface DialogData {
  version: Version;
}

@Component({
  selector: 'idl-version-details',
  templateUrl: './version-details.component.html',
  styleUrls: ['./version-details.component.scss']
})
export class VersionDetailsComponent {
  version: Version;

  constructor(
    private _dialogRef: MatDialogRef<VersionDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData
  ) {
    if (dialogData) {
      this.version = dialogData.version;
    }
  }

  onDelete() {
    if (
      confirm('Are you sure you want to delete the file: ' + this.version.name)
    ) {
      this._dialogRef.close('delete');
    }
  }
}
