import { ResponseInterface } from './types';

export class MetadataSet {
  constructor(
    public metadataSetId: number,
    public applicationId: number,
    public name: string,
    public title: string,
    public comments: string,
    public lastEditDate: Date,
    public lastEditUserId: number
  ) {}

  static adapt(item: any): MetadataSet {
    return new MetadataSet(
      item.metadataSetId,
      item.applicationId,
      item.name,
      item.title,
      item.comments,
      new Date(item.lastEditDate),
      item.lastEditUserID
    );
  }
}

export class MetadataSetsData {
  constructor(public metadataSets: MetadataSet[]) {}
}

export class MetadataSetResponse implements ResponseInterface {
  status = '';
  data: MetadataSet;
  constructor() {}
}

export class MetadataSetsResponse implements ResponseInterface {
  status = '';
  data: MetadataSetsData;
  constructor() {}
}
