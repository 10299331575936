import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ApiUrlInterceptor implements HttpInterceptor {
  constructor(@Inject('BASE_API_URL') private baseUrl: string) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.substr(0, req.url.indexOf('/')) === 'api') {
      const apiReq = req.clone({
        url: `${this.baseUrl}/${req.url}`
      });
      return next.handle(apiReq);
    } else {
      return next.handle(req);
    }
  }
}
