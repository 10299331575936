import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { AppRoles } from '../models/app-roles.model';

/**
 * Access checker service.
 *
 * Injects `NbRoleProvider` to determine current user role, and checks access permissions using `NbAclService`
 */
@Injectable({
  providedIn: 'root'
})
export class IdAppRoleChecker {
  private baseUrl = 'api/applications';

  constructor(protected http: HttpClient) {}

  /**
   * Checks whether access is granted or not
   *
   * @param {number} appId
   * @param {string} role
   * @returns {Observable<boolean>}
   */
  isGranted(appId: number, role: string): Observable<boolean> {
    const url = `${this.baseUrl}/${appId}/haverole`;
    const params = new HttpParams().set('role', role);
    const options = { params: params };
    return this.http.get(url, options).pipe(
      // Adapt each item in the raw data array
      map((response: AppRoles) => response.data),
      catchError(err => of(false))
    );
  }
}
