import { Industry } from './industry.model';
import { MetadataOverride } from './metadata.model';
import { ResponseInterface } from './types';

export enum LicenseTypes {
  Demo = 1,
  Subscription = 2,
  Perpetual = 3
}

export class LicenseType {
  constructor(public id: number, public name: string) {}
}

export class License {
  constructor(
    public licenseRecordId: number,
    public applicationId: number,
    public metadataSetId: number,
    public licenseHolderId: number,
    public applicationName: string,
    public licenseHolderName: string,
    public licenseHolderEmail: string,
    public metadataSetName: string,
    public metadataSetTitle: string,
    public createdByUserName: string,
    public licenseGuid: string,
    public creationdate: Date,
    public createdbyid: number,
    public type: string,
    public comments: string,
    public expirydate: Date,
    public licenseData: string,
    public organisationId: number,
    public organisationName: string,
    public revoked: boolean,
    public nextId: number,
    public noLicenseDownload: boolean,
    public additionalEmails?: string,
    public industries?: Industry[]
  ) {}

  static adapt(item: any): License {
    return new License(
      item.licenseRecordId,
      item.applicationId,
      item.metadataSetId,
      item.licenseHolderId,
      item.applicationName,
      item.licenseHolderName,
      item.licenseHolderEmail,
      item.metadataSetName,
      item.metadataSetTitle,
      item.createdByUserName,
      item.licenseGuid,
      new Date(item.creationDate),
      item.createdByUserId,
      LicenseTypes[item.licenseType],
      item.comments,
      new Date(item.expiryDate),
      item.licenseData,
      item.organisationId,
      item.organisationName,
      item.revoked,
      item.nextId,
      item.noLicenseDownload,
      item.additionalEmails,
      item.industries.map(Industry.adapt)
    );
  }
}

export class LicensesData {
  constructor(public licenses: License[]) {}
}

export class LicensesResponse implements ResponseInterface {
  status = '';
  data: LicensesData;
  constructor() {}
}

export class LicenseResponse implements ResponseInterface {
  status = '';
  data: License;
  constructor() {}
}

export class CreateLicenseRequest {
  constructor(
    public applicationId: number,
    public metadataSetId: number,
    public licenseHolderId: number,
    public licenseType: string,
    public expiryDate: Date,
    public comments: string,
    public metadataOverrides: MetadataOverride[],
    public idSubsInvoiceId?: number,
    public additionalEmails?: string
  ) {}
}

export class ChangeLicenseRequest {
  constructor(public comments?: string, public additionalEmails?: string) {}
}
