import { InjectionToken, NgModule } from '@angular/core';
import * as Rollbar from 'rollbar'; // When using Typescript < 3.6.0.
import { environment } from '../../../environments/environment';

const rollbarConfig = {
  accessToken: '9845d89933644ae19bed71084427d45d',
  captureUncaught: true,
  captureUnhandledRejections: true,

  payload: {
    environment: environment.envName,
    client: {
      javascript: {
        source_map_enabled: true, // true by default

        // -- Add this into your configuration ---
        code_version: environment.versions.app,
        // ---------------------------------------

        // Optionally have Rollbar guess which frames the error was
        // thrown from when the browser does not provide line
        // and column numbers.
        guess_uncaught_frames: true
      }
    }
  }
};

export function rollbarFactory() {
  return environment.production ? new Rollbar(rollbarConfig) : null;
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@NgModule({
  providers: [{ provide: RollbarService, useFactory: rollbarFactory }]
})
export class RollbarModule {}
