import { ResponseInterface } from './types';

export enum MetadataType {
  Value = 1,
  Text,
  DateTime
}

export let MetadataTypeList = [
  {
    value: MetadataType[MetadataType.Value],
    title: MetadataType[MetadataType.Value]
  },
  {
    value: MetadataType[MetadataType.Text],
    title: MetadataType[MetadataType.Text]
  },
  {
    value: MetadataType[MetadataType.DateTime],
    title: MetadataType[MetadataType.DateTime]
  }
];

export class MetadataDefinition {
  constructor(
    public metadataDefinitionId: number = 0,
    public applicationId: number = 0,
    public keyName: string = '',
    public keyType: string = '',
    public keyDefault: string = ''
  ) {}

  static adapt(item: any): MetadataDefinition {
    return new MetadataDefinition(
      item.metadataDefinitionId,
      item.applicationId,
      item.keyName,
      MetadataType[item.keyType],
      item.keyType === MetadataType['DateTime']
        ? new Date(item.keyDefault)
        : item.keyDefault
    );
  }
  static adaptClean(item: any): MetadataDefinition {
    return new MetadataDefinition(
      0,
      0,
      item.keyName,
      MetadataType[item.keyType],
      item.keyType === MetadataType['DateTime']
        ? new Date(item.keyDefault)
        : item.keyDefault
    );
  }
}

export class MetadataDefinitionsData {
  constructor(public metadataDefinitions: MetadataDefinition[]) {}
}

export class MetadataDefinitionResponse implements ResponseInterface {
  status = '';
  data: MetadataDefinition;
  constructor() {}
}

export class MetadataDefinitionsResponse implements ResponseInterface {
  status = '';
  data: MetadataDefinitionsData;
  constructor() {}
}
