import { ResponseInterface } from './types';

export class AppUserSettings {
  constructor();
  constructor(
    applicationId: number,
    applicationName: string,
    userId: number,
    mailPolicyName: string
  );
  constructor(
    public applicationId?: number,
    public applicationName?: string,
    public userId?: number,
    public mailPolicyName?: string
  ) {}

  static adapt(item: any): AppUserSettings {
    return new AppUserSettings(
      item.applicationId,
      item.applicationName,
      item.userId,
      item.mailPolicyName
    );
  }
}

export class AppUserSettingsData {
  constructor(public users: AppUserSettings[]) {}
}

export class AppUserSettingsResponse implements ResponseInterface {
  status = '';
  data: AppUserSettingsData;
  constructor() {}
}
