import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VersionGroup } from '~core/models/version-group.model';

@Component({
  selector: 'idl-dialog-group-prompt',
  templateUrl: './dialog-group-prompt.component.html',
  styleUrls: ['./dialog-group-prompt.component.scss']
})
export class DialogGroupPromptComponent {
  newGroup: VersionGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      this.newGroup = data.editGroup;
    } else {
      this.newGroup = new VersionGroup(0, '');
      this.newGroup.startDate = new Date();
    }
  }
}
