export const PERMISSIONS = {
  accessControl: {
    guest: {
      view: 'login'
    },
    LicenseHolder: {
      view: ['user', 'my_licenses']
    },
    Administrator: {
      parent: 'LicenseHolder',
      view: [
        'management',
        'admin',
        'logs',
        'users',
        'applications_list',
        'create_app',
        'license_list',
        'license_history',
        'license_comments',
        'organisations',
        'industries'
      ],
      edit: [
        'application',
        'application_email_templates',
        'application_hidden',
        'application_users',
        'application_versions',
        'application_metadata',
        'application_licenses',
        'crm',
        'org',
        'versions',
        'roles',
        'create_demo_access'
      ],
      renew: ['license'],
      revoke: ['license'],
      delete: ['file', 'license']
    },
    Manager: {
      parent: 'LicenseHolder',
      view: [
        'management',
        'manager',
        'applications_list',
        'application_hidden',
        'users',
        'create_app',
        'license_list',
        'license_history',
        'license_comments',
        'organisations',
        'industries'
      ],
      edit: [
        'application',
        'application_email_templates',
        'application_users',
        'application_licenses',
        'crm',
        'org'
      ],
      renew: ['license'],
      revoke: ['license'],
      delete: []
    },
    Developer: {
      parent: 'LicenseHolder',
      view: ['management', 'developer', 'applications_list'],
      edit: [
        'application',
        'application_versions',
        'application_metadata',
        'crm',
        'org',
        'versions'
      ]
    },
    SuperUser: {
      view: ['management', 'applications_list', 'user', 'users', 'logs'],
      edit: [
        'application',
        'application_email_templates',
        'application_users',
        'application_hidden',
        'application_versions',
        'application_metadata',
        'application_licenses',
        'crm',
        'roles'
      ]
    }
  }
};
