import { MatDateFormats } from '@angular/material/core';

/**
 * Custom Date-Formats and Adapter (using https://github.com/iamkun/dayjs)
 */
export const MAT_DAYJS_DATETIME_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'l'
  },
  display: {
    dateInput: 'l',
    dateA11yLabel: 'LL',
    monthLabel: 'MMM',
    monthYearLabel: 'MMM YYYY',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};
