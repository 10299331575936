export enum Roles {
  LicenseHolder = 1,
  Developer,
  Manager,
  Administrator,
  SuperUser
}

export class Role {
  constructor(public id: number, public name: string) {}
}
