<div *ngIf="user" class="test">
  <div class="app-settings">
    <form fxLayout="column" fxLayoutAlign="space-around" (ngSubmit)="onSubmit()" [formGroup]="form" class="app-settings-form">
      <div *ngFor="let setting of user.applicationSettings">
        <mat-form-field appearance="fill">
          <mat-label>{{setting.applicationName}}</mat-label>
          <mat-select [id]="setting.applicationId.toString()" [formControlName]="setting.applicationId">
            <mat-option *ngFor="let opt of options" [value]="opt">{{opt}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  </div>
  <div class="save-button">
    <button mat-raised-button color="primary" (click)="onSubmit()" [disabled]="!form.valid">Save</button>
  </div>
</div>