<div class="version-container container">
  <div class="row">
    <div *ngIf="showName && version.name" class="version-name col-12">{{ version.name }}</div>
  </div>
  <div class="row">
    <div class="info-container col-md-3" *ngIf="version">
      <div *ngIf="showVersion && version.version" class="version-version">Version: {{ version.version }}</div>
      <div class="row">
        <div class="col-md-6">
          <div *ngIf="showDownload && version.fileId" class="version-download">
            <button [disabled]="downloadLocked" mat-icon-button matTooltip="Download Version"
              (click)="downloadVersion()">
              <mat-icon>cloud_download</mat-icon>
            </button>
          </div>
        </div>
        <div class="col-md-6">
          <div *ngIf="showEdit && version.fileId" class="version-edit">
            <button mat-icon-button matTooltip="Edit Version" (click)="editVersion()">
              <mat-icon>create</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="description-container col-md-9" *ngIf="version">
      <div *ngIf="showDescription && version.comments" class="version-description">{{ version.comments }}</div>
      <div *ngIf="showDescription && version.hidden" class="version-hidden">version marked as hidden.</div>
      <div *ngIf="showDescription && version.uploadDate" class="version-date">Release Date:
        {{ version.uploadDate|date:'mediumDate' }}
      </div>
    </div>
  </div>
</div>