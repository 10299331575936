import { createSelector } from '@ngrx/store';
import { selectAuthState } from '../../../core/core.state';
import { AuthState } from '../models/auth-state.model';

export const selectAuth = createSelector(
  selectAuthState,
  (state: AuthState) => state
);

export const selectUserId = createSelector(
  selectAuthState,
  (state: AuthState) => state.id
);

export const selectIsAuthenticated = createSelector(
  selectAuthState,
  (state: AuthState) => state.isAuthenticated
);

export const selectUserPicture = createSelector(
  selectAuthState,
  (state: AuthState) => state.picture
);

export const selectRoles = createSelector(
  selectAuthState,
  (state: AuthState) => state.roles
);
