import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { RoleGuardService } from '~app/core/core.module';
import { LicensesComponent } from './licenses.component';

const routes: Routes = [
  {
    path: '',
    component: LicensesComponent,
    canActivate: [AuthGuard, RoleGuardService],
    data: {
      expectedRoles: ['Manager', 'Administrator']
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LicensesRoutingModule {}
