import {
  Directive,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { takeWhile } from 'rxjs/operators';

import { IdAppRoleChecker } from '../services/app-role-checker.service';

@Directive({ selector: '[idlIsAppRole]' })
export class IdIsGrantedDirective implements OnDestroy {
  private alive = true;
  private hasView = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private accessChecker: IdAppRoleChecker
  ) {}

  @Input() set idlIsAppRole([appId, role]: [number, string]) {
    this.accessChecker
      .isGranted(appId, role)
      .pipe(takeWhile(() => this.alive))
      .subscribe((can: boolean) => {
        if (can && !this.hasView) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.hasView = true;
        } else if (!can && this.hasView) {
          this.viewContainer.clear();
          this.hasView = false;
        }
      });
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
