import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  Application,
  ApplicationResponse,
  ApplicationsResponse
} from '../models/application.model';

@Injectable({
  providedIn: 'root'
})
export class ApplicationsService {
  private baseUrl = 'api/applications';

  constructor(private http: HttpClient) {}

  list(roles: string[]): Observable<Application[]> {
    const url = `${this.baseUrl}/`;
    let params = new HttpParams();
    if (roles) {
      roles.forEach(role => {
        params = params.append('role', role);
      });
    }
    const options = roles ? { params: params } : {};
    return this.http.get(url, options).pipe(
      // Adapt each item in the raw data array
      map((response: ApplicationsResponse) =>
        response.data.applications.map(Application.adapt)
      )
      // catchError(this.handleError)
    );
  }

  get(appID: number): Observable<Application> {
    const url = `${this.baseUrl}/${appID}`;
    return this.http.get(url).pipe(
      // Adapt each item in the raw data array
      map((response: ApplicationResponse) => Application.adapt(response.data))
    );
  }

  edit(app: Application): Observable<Application> {
    const url = `${this.baseUrl}/`;
    return this.http.put(url, app).pipe(
      // Adapt each item in the raw data array
      map((response: ApplicationResponse) => Application.adapt(response.data))
    );
  }
}
