import { AuthState } from '../../models/auth-state.model';
import { AuthActions, AuthActionTypes } from '../actions/auth.actions';

export const initialState: AuthState = {
  isAuthenticated: false,
  name: null,
  email: null,
  picture: null,
  roles: null,
  id: null,
  errorMessage: null,
  loaded: false,
  loading: false,
  refreshing: false
};

export function authReducer(
  state = initialState,
  action: AuthActions
): AuthState {
  switch (action.type) {
    case AuthActionTypes.LoginSuccess:
      return {
        ...state,
        isAuthenticated: true,
        name: action.payload.user.name,
        email: action.payload.user.email,
        picture: action.payload.user.picture,
        id: action.payload.id,
        roles: action.payload.roles
      };

    case AuthActionTypes.LogoutConfirmed:
      return initialState; // the initial state has isLoggedIn set to false

    default:
      return state;
  }
}

export const selectIsLoggedIn = (state: AuthState) => state.isAuthenticated;
