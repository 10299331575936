import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbSecurityModule } from '@nebular/security';
import { TranslateModule } from '@ngx-translate/core';
import { FilterSelectModule } from '../../core/utils/filter-select/filter-select.module';
import { MaterialModule } from '../../shared/material-module';
import { LicensesModule } from '../licenses/licenses.module';
import { UserDetailsComponent } from './user-details/user-details.component';
import { UserDownloadsComponent } from './user-details/user-downloads/user-downloads.component';
import { UserLoginsComponent } from './user-details/user-logins/user-logins.component';
import { UserAppSettingsComponent } from './user-edit/user-app-settings/user-app-settings.component';
import { UserEditDialogComponent } from './user-edit/user-edit-dialog.component';
import { UserEditPageComponent } from './user-edit/user-edit-page.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { UsersRoutingModule } from './users-routing.module';
import { UsersComponent } from './users.component';

export { UsersComponent, UserEditPageComponent };

@NgModule({
  imports: [
    UsersRoutingModule,

    CommonModule,
    FilterSelectModule,
    FormsModule,
    LicensesModule,
    MaterialModule,
    NbSecurityModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  declarations: [
    UsersComponent,
    UserEditComponent,
    UserEditPageComponent,
    UserEditDialogComponent,
    UserLoginsComponent,
    UserDetailsComponent,
    UserDownloadsComponent,
    UserAppSettingsComponent
  ],
  entryComponents: [
    UserEditComponent,
    UserDetailsComponent,
    UserEditDialogComponent
  ],
  exports: [
    UsersComponent,
    UserEditComponent,
    UserDetailsComponent,
    UserDownloadsComponent,
    UserAppSettingsComponent
  ]
})
export class UsersModule {}
