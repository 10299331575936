import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbAccessChecker } from '@nebular/security';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import browser from 'browser-detect';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthLocalService, selectUserPicture } from '~core/auth/auth.module';
import * as fromAuth from '~core/auth/store/actions/auth.actions';
import {
  AppState,
  LocalStorageService,
  Login,
  Logout,
  routeAnimations,
  selectEffectiveTheme,
  selectIsAuthenticated,
  selectSettingsLanguage,
  selectSettingsStickyHeader
} from '~core/core.module';
import {
  actionSettingsChangeAnimationsPageDisabled,
  actionSettingsChangeLanguage
} from '~core/settings/settings.actions';
import { environment as env } from '~environments';
import { AITMenuItem, MENU_ITEMS } from './app-menu';

@Component({
  selector: 'ait-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeAnimations]
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  isProd = env.production;
  envName = env.envName;
  version = env.versions.app;
  year = new Date().getFullYear();
  logo = '../../assets/logo.png';
  languages = ['en', 'de', 'sk', 'fr', 'es', 'pt-br', 'zh-cn', 'he'];
  navigation = MENU_ITEMS;
  navigationSideMenu: AITMenuItem[] = [
    ...this.navigation
    // { link: 'settings', label: 'ait.menu.settings' }
  ];

  isAuthenticated$: Observable<boolean>;
  userPicture$: Observable<string>;
  stickyHeader$: Observable<boolean>;
  language$: Observable<string>;
  theme$: Observable<string>;

  screenWidth: number;

  constructor(
    public authLocal: AuthLocalService,
    private accessChecker: NbAccessChecker,
    updates$: Actions,
    private router: Router,
    private store: Store<AppState>,
    private storageService: LocalStorageService
  ) {
    // Hide auth menu items by default
    this.hideAuthMenuItems();
    // set screenWidth on page load
    this.screenWidth = window.innerWidth;
    window.onresize = () => {
      // set screenWidth on screen size change
      this.screenWidth = window.innerWidth;
    };

    updates$
      .pipe(
        ofType<fromAuth.LoginSuccess>(fromAuth.AuthActionTypes.LoginSuccess),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => this.authMenuItems());
  }

  private static isIEorEdgeOrSafari() {
    return ['ie', 'edge', 'safari'].includes(browser().name);
  }

  ngOnInit(): void {
    // On initial load, set up local auth streams
    this.authLocal.localAuthSetup();

    this.storageService.testLocalStorage();
    if (AppComponent.isIEorEdgeOrSafari()) {
      this.store.dispatch(
        actionSettingsChangeAnimationsPageDisabled({
          pageAnimationsDisabled: true
        })
      );
    }

    this.isAuthenticated$ = this.store.pipe(select(selectIsAuthenticated));
    this.userPicture$ = this.store.pipe(select(selectUserPicture));
    this.stickyHeader$ = this.store.pipe(select(selectSettingsStickyHeader));
    this.language$ = this.store.pipe(select(selectSettingsLanguage));
    this.theme$ = this.store.pipe(select(selectEffectiveTheme));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onLoginClick() {
    this.store.dispatch(new Login(this.router.url));
  }

  onLogoutClick() {
    this.store.dispatch(new Logout());
  }

  authMenuItems() {
    this.navigation.forEach(item => {
      this.authMenuItem(item);
    });
  }

  hideAuthMenuItems() {
    this.navigation.forEach(menuItem => {
      if (
        menuItem.data &&
        menuItem.data['permission'] &&
        menuItem.data['resource']
      ) {
        menuItem.hidden = true;
      } else {
        menuItem.hidden = false;
      }
    });
  }

  authMenuItem(menuItem: AITMenuItem) {
    if (
      menuItem.data &&
      menuItem.data['permission'] &&
      menuItem.data['resource']
    ) {
      this.accessChecker
        .isGranted(menuItem.data['permission'], menuItem.data['resource'])
        .subscribe(granted => {
          menuItem.hidden = !granted;
        });
    } else {
      menuItem.hidden = false;
    }
  }

  onLanguageSelect({ value: language }) {
    this.store.dispatch(actionSettingsChangeLanguage({ language }));
  }
}
