import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { NotificationService } from '~app/core/core.module';
import { LicenseService } from '~core/data/license.service';
import { License } from '~core/models/license.model';

@Component({
  selector: 'idl-license-history',
  templateUrl: './license-history.component.html',
  styleUrls: ['./license-history.component.scss']
})
export class LicenseHistoryComponent
  implements OnInit, AfterViewInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  displayedColumns: string[] = [
    'licenseHolderName',
    'metadataSetName',
    'type',
    'expirydate',
    'createdByUserName',
    'revokeButton'
  ];

  license: License;
  selectedRowIndex = '';

  @Input() licenseId: number;

  dataSource = new MatTableDataSource<License>();
  licenses: License[];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  isLoading = true;

  constructor(
    private _licenseService: LicenseService,
    private readonly _notificationService: NotificationService
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    if (this.licenseId) {
      this._licenseService
        .get(this.licenseId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(license => {
          this.selectedRowIndex = license.licenseGuid;
          this.license = license;
        });
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;

    /* now it's okay to set large data source... */
    this.loadLicenseData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  loadLicenseData() {
    let query: Observable<License[]>;

    query = this._licenseService
      .getLicenseHistory(this.licenseId)
      .pipe(takeUntil(this.unsubscribe$), take(1));

    query.subscribe(
      data => {
        this.dataSource.data = data;
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
      }
    );
  }

  rowSelected(license: License) {
    this.selectedRowIndex = license.licenseGuid;
    this.license = license;
  }

  onRevoke(license: License) {
    if (confirm('Are you sure you want to revoke this license?')) {
      this._licenseService
        .delete(license.licenseRecordId)
        .pipe(take(1))
        .subscribe(
          data => {
            license.revoked = true;
            this._notificationService.success('License revoked.');
          },
          error => {
            this._notificationService.error('Error revoking license.');
          }
        );
    }
  }
}
