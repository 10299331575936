import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import * as dayjs from 'dayjs';
import { Dayjs } from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/he';
import 'dayjs/locale/pt-br';
import 'dayjs/locale/sk';
import 'dayjs/locale/zh-cn';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import * as localeData from 'dayjs/plugin/localeData';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.extend(localeData);
// Need this so setHour etc work
// dayjs.extend(badMutable);
export interface DayJsDateAdapterOptions {
  /**
   * Turns the use of utc dates on or off.
   * Changing this will change how Angular Material components like DatePicker output dates.
   * {@default false}
   */
  useUtc?: boolean;
}

/** InjectionToken for Dayjs date adapter to configure options. */
export const MAT_DAYJS_DATE_ADAPTER_OPTIONS = new InjectionToken<
  DayJsDateAdapterOptions
>('MAT_DAYJS_DATE_ADAPTER_OPTIONS', {
  providedIn: 'root',
  factory: MAT_DAYJS_DATE_ADAPTER_OPTIONS_FACTORY
});

export function MAT_DAYJS_DATE_ADAPTER_OPTIONS_FACTORY(): DayJsDateAdapterOptions {
  return {
    useUtc: false
  };
}

/** The default hour names to use if Intl API is not available. */
const DEFAULT_HOUR_NAMES = range(24, i => (i === 0 ? '00' : String(i)));

/** The default minute names to use if Intl API is not available. */
const DEFAULT_MINUTE_NAMES = range(60, String);

/** Creates an array and fills it with values. */
function range<T>(length: number, valueFunction: (index: number) => T): T[] {
  const valuesArray = Array(length);
  for (let i = 0; i < length; i++) {
    valuesArray[i] = valueFunction(i);
  }
  return valuesArray;
}

/** Adapts Dayjs Dates for use with Angular Material. */
@Injectable()
export class DayjsDateAdapter extends DateAdapter<Dayjs> {
  private _useUTC: boolean;
  // private _getFirstDayOfWeek: MatLuxonDateAdapterOptions['firstDayOfWeek'];

  private localeData: {
    firstDayOfWeek: number;
    longMonths: string[];
    shortMonths: string[];
    dates: string[];
    longDaysOfWeek: string[];
    shortDaysOfWeek: string[];
    narrowDaysOfWeek: string[];
  };

  constructor(
    @Optional() @Inject(MAT_DATE_LOCALE) public dateLocale: string,
    @Optional()
    @Inject(MAT_DAYJS_DATE_ADAPTER_OPTIONS)
    private options?: DayJsDateAdapterOptions
  ) {
    super();

    this._useUTC = options ? !!options.useUtc : false;
    this.initializeParser(dateLocale);
  }

  private initializeParser(dateLocale: string) {
    if (this.shouldUseUtc) {
      dayjs.extend(utc);
    }

    dayjs.extend(localizedFormat);
    dayjs.extend(customParseFormat);
    dayjs.extend(localeData);

    this.setLocale(dateLocale);
  }

  setLocale(locale: string): void {
    super.setLocale(locale.toLowerCase());

    const dayJsLocaleData = this.dayJs().localeData();
    this.localeData = {
      firstDayOfWeek: dayJsLocaleData.firstDayOfWeek(),
      longMonths: dayJsLocaleData.months(),
      shortMonths: dayJsLocaleData.monthsShort(),
      dates: range(31, i => this.createDate(2017, 0, i + 1).format('D')),
      longDaysOfWeek: range(7, i =>
        this.dayJs()
          .set('day', i)
          .format('dddd')
      ),
      shortDaysOfWeek: dayJsLocaleData.weekdaysShort(),
      narrowDaysOfWeek: dayJsLocaleData.weekdaysMin()
    };
  }

  getYear(date: Dayjs): number {
    return date.year();
  }

  getMonth(date: Dayjs): number {
    return date.month();
  }

  getDate(date: Dayjs): number {
    return date.date();
  }

  getHours(date: dayjs.Dayjs): number {
    return date.hour();
  }
  getMinutes(date: dayjs.Dayjs): number {
    return date.minute();
  }
  getSeconds(date: dayjs.Dayjs): number {
    return date.second();
  }
  getMilliseconds(date: Dayjs): number {
    return date.millisecond();
  }
  setHours(date: Dayjs, value: number): Dayjs {
    return date.hour(value);
  }
  setMinutes(date: Dayjs, value: number): Dayjs {
    return date.minute(value);
  }
  setSeconds(date: Dayjs, value: number, ms?: number): Dayjs {
    const newDate = date.second(value);
    return ms ? newDate.millisecond(ms) : newDate.millisecond(0);
  }

  getDayOfWeek(date: Dayjs): number {
    return this.dayJs(date).day();
  }

  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    return style === 'long'
      ? this.localeData.longMonths
      : this.localeData.shortMonths;
  }

  getDateNames(): string[] {
    return this.localeData.dates;
  }

  getHourNames(): string[] {
    return DEFAULT_HOUR_NAMES;
  }

  getMinuteNames(): string[] {
    return DEFAULT_MINUTE_NAMES;
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    if (style === 'long') {
      return this.localeData.longDaysOfWeek;
    }
    if (style === 'short') {
      return this.localeData.shortDaysOfWeek;
    }
    return this.localeData.narrowDaysOfWeek;
  }

  getYearName(date: Dayjs): string {
    return date.format('YYYY');
  }

  getFirstDayOfWeek(): number {
    return this.localeData.firstDayOfWeek;
  }

  getNumDaysInMonth(date: Dayjs): number {
    return date.daysInMonth();
  }

  clone(date: Dayjs): Dayjs {
    return date.clone();
  }

  createDate(
    year: number,
    month: number,
    date: number,
    hours?: number,
    minutes?: number,
    seconds?: number,
    ms?: number
  ): Dayjs {
    if (month < 0 || month > 11) {
      throw Error(
        `Invalid month index "${month}". Month index has to be between 0 and 11.`
      );
    }
    if (date < 1) {
      throw Error(`Invalid date "${date}". Date has to be greater than 0.`);
    }

    let returnDayjs = this.dayJs()
      .set('year', year)
      .set('month', month)
      .set('date', date);

    returnDayjs = hours ? returnDayjs.hour(hours) : returnDayjs.hour(0);
    returnDayjs = minutes ? returnDayjs.minute(minutes) : returnDayjs.minute(0);
    returnDayjs = seconds ? returnDayjs.second(seconds) : returnDayjs.second(0);
    returnDayjs = ms ? returnDayjs.millisecond(ms) : returnDayjs.millisecond(0);

    return returnDayjs;
  }

  today(): Dayjs {
    return this.dayJs();
  }

  parse(value: any, parseFormat: string): Dayjs | null {
    if (value && typeof value === 'string') {
      const longFormats = parseFormat.split(' ').map(p =>
        dayjs()
          .locale(this.locale)
          .localeData()
          .longDateFormat(p)
      );
      const longDateFormat = longFormats.join(' '); // MM/DD/YYY or DD-MM-YYYY, etc.
      let parsed = this.dayJs(value, longDateFormat);
      if (parsed.isValid()) {
        // string value is exactly like long date format
        return parsed;
      }

      if (value.length === 8) {
        // user might have typed 24012020 or 01242020
        // strip long date format of non-alphabetic characters so we get MMDDYYYY or DDMMYYYY
        const format = longDateFormat.replace(/[\W_]+/g, '');
        parsed = this.dayJs(value, format, this.locale);
        if (parsed.isValid()) {
          return parsed;
        }
      }
      if (value.length < 6 && value.length > 2) {
        // user might have typed 01/24, 24-01, 1/24, 24/1 or 24-1
        // try to extract month and day part and parse them with custom format
        let parts = [];
        if (value.indexOf('/') !== -1) {
          parts = value.split('/');
        }
        if (value.indexOf('-') !== -1) {
          parts = value.split('-');
        }
        if (value.indexOf('.') !== -1) {
          parts = value.split('.');
        }
        if (parts.length === 2) {
          let dayPart: string;
          let monthPart: string;
          if (longDateFormat.startsWith('D')) {
            dayPart = parts[0];
            monthPart = parts[1];
          } else if (parts.length > 1) {
            monthPart = parts[0];
            dayPart = parts[1];
          }
          if (monthPart.length === 1) {
            monthPart = 0 + monthPart;
          }
          if (dayPart.length === 1) {
            dayPart = 0 + dayPart;
          }
          parsed = this.dayJs(dayPart + monthPart, 'DDMM', this.locale);
          if (parsed.isValid()) {
            return parsed;
          }
        }
      }
      if (value.length === 2) {
        // user might have typed 01, parse DD only
        const format = 'DD';
        parsed = this.dayJs(value, format, this.locale);
        if (parsed.isValid()) {
          return parsed;
        }
      }
      if (value.length === 1) {
        // user might have typed 1, parse D only
        const format = 'D';
        parsed = this.dayJs(value, format, this.locale);
        if (parsed.isValid()) {
          return parsed;
        }
      }

      // not able to parse anything sensible, return something invalid so input can be corrected
      return this.dayJs(null);
    }

    return value ? this.dayJs(value).locale(this.locale) : null;
  }

  format(date: Dayjs, displayFormat: string): string {
    if (!this.isValid(date)) {
      throw Error('DayjsDateAdapter: Cannot format invalid date.');
    }
    return date.locale(this.locale).format(displayFormat);
  }

  addCalendarYears(date: Dayjs, years: number): Dayjs {
    return date.add(years, 'year');
  }

  addCalendarMonths(date: Dayjs, months: number): Dayjs {
    return date.add(months, 'month');
  }

  addCalendarDays(date: Dayjs, days: number): Dayjs {
    return date.add(days, 'day');
  }

  addCalendarHours(date: Dayjs, hours: number): Dayjs {
    return date.add(hours, 'hour');
  }

  addCalendarMinutes(date: Dayjs, minutes: number): Dayjs {
    return date.add(minutes, 'minute');
  }

  addCalendarSeconds(date: Dayjs, seconds: number, ms?: number): Dayjs {
    const newDate = date.add(seconds, 'second');
    if (ms) return newDate.add(ms, 'millisecond');
    return newDate;
  }

  toIso8601(date: Dayjs): string {
    return date.toISOString();
  }

  /**
   * Attempts to deserialize a value to a valid date object. This is different from parsing in that
   * deserialize should only accept non-ambiguous, locale-independent formats (e.g. a ISO 8601
   * string). The default implementation does not allow any deserialization, it simply checks that
   * the given value is already a valid date object or null. The `<mat-datepicker>` will call this
   * method on all of it's `@Input()` properties that accept dates. It is therefore possible to
   * support passing values from your backend directly to these properties by overriding this method
   * to also deserialize the format used by your backend.
   * @param value The value to be deserialized into a date object.
   * @returns The deserialized date object, either a valid date, null if the value can be
   *     deserialized into a null date (e.g. the empty string), or an invalid date.
   */
  deserialize(value: any): Dayjs | null {
    let date;
    if (value instanceof Date) {
      date = this.dayJs(value);
    } else if (this.isDateInstance(value)) {
      // Note: assumes that cloning also sets the correct locale.
      return this.clone(value);
    }
    if (typeof value === 'string') {
      if (!value) {
        return null;
      }
      date = this.dayJs(value).toISOString();
    }
    if (date && this.isValid(date)) {
      return this.dayJs(date); // NOTE: Is this necessary since Dayjs is immutable and Moment was not?
    }
    return super.deserialize(value);
  }

  isDateInstance(obj: any): boolean {
    return dayjs.isDayjs(obj);
  }

  isValid(date: Dayjs): boolean {
    return date.isValid();
  }

  invalid(): Dayjs {
    return this.dayJs(null);
  }

  private dayJs(input?: any, format?: string, locale?: string): Dayjs {
    if (this.shouldUseUtc) {
      return dayjs.utc(input, format);
    } else {
      return dayjs(input, format, locale);
    }
  }

  private get shouldUseUtc(): boolean {
    const { useUtc }: DayJsDateAdapterOptions = this.options || {};
    return !!useUtc;
  }
}
