import {
  HttpClient,
  HttpEventType,
  HttpParams,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {
  Version,
  VersionResponse,
  VersionUpload
} from '../models/version.model';

@Injectable({
  providedIn: 'root'
})
export class FilesService {
  private baseUrl = 'api/files';

  constructor(private http: HttpClient) {}

  addVersion(file: File, version: VersionUpload, versionGroupId: number) {
    const url = `${this.baseUrl}`;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('version', version.version);
    formData.append('versionGroupId', versionGroupId.toString());
    formData.append('comments', version.comments);
    formData.append('importantUpdate', version.importantUpdate.toString());
    formData.append('noNotifications', version.noNotifications.toString());
    if (version.overrideDate)
      formData.append('overrideDate', version.overrideDate.toISOString());

    const newVersion: VersionUpload = null;
    let progress = 0;

    return this.http
      .post<any>(url, formData, {
        reportProgress: true,
        observe: 'events'
      })
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              progress = Math.round((100 * event.loaded) / event.total);
              return {
                message: 'progress',
                progress: progress,
                version: newVersion
              };

            case HttpEventType.Response:
              return {
                message: 'Finished',
                progress: 100,
                version: Version.adapt(event.body.data as VersionResponse)
              };
            case HttpEventType.ResponseHeader:
              return {
                message: 'Finished',
                progress: 100,
                version: null
              };
            default:
              return {
                message: `Unhandled event: ${event.type}`,
                progress: progress,
                version: newVersion
              };
          }
        })
      );
  }

  get(versionId: number) {
    const url = `${this.baseUrl}/${versionId}`;
    return this.http.get(url).pipe(
      // tap(user => console.log(user)),
      // Adapt each item in the raw data array
      map((response: VersionResponse) => Version.adapt(response.data))
      // catchError(this.handleError)
    );
  }

  download(versionId: number, licenseid = 0) {
    const url = `${this.baseUrl}/${versionId}/download`;
    let params = new HttpParams();
    if (licenseid) params = params.append('licenseid', licenseid.toString());
    // Note here that you cannot use the generic get<Blob> as it does not compile:
    // instead you "choose" the appropriate API in this way.
    const req = new HttpRequest('GET', url, {
      params: params,
      reportProgress: true,
      responseType: 'blob'
    });
    let progress = 0;
    const newBlob: Blob = null;
    return this.http.request(req).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.DownloadProgress:
            progress = Math.round((100 * event.loaded) / event.total);
            return {
              message: 'progress',
              progress: progress,
              type: '',
              blob: newBlob
            };

          case HttpEventType.Response:
            return {
              message: 'Finished',
              progress: 0,
              type: event.headers.get('content-type'),
              blob: event.body as Blob
            };

          default:
            return {
              message: `Unhandled event: ${event.type}`,
              progress: progress,
              type: '',
              blob: newBlob
            };
        }
      })
    );
  }

  edit(versionId: number, version: Version) {
    const url = `${this.baseUrl}/${versionId}`;

    return this.http.put(url, version).pipe(
      // Adapt each item in the raw data array
      map((response: VersionResponse) => Version.adapt(response.data))
    );
  }

  delete(versionId: number): any {
    const url = `${this.baseUrl}/${versionId}`;
    return this.http.delete(url);
  }
}
