import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Version, VersionsResponse } from '../models/version.model';
import {
  VersionGroup,
  VersionGroupResponse,
  VersionGroupsResponse
} from '../models/version-group.model';

@Injectable({
  providedIn: 'root'
})
export class VersionsService {
  private baseUrl = 'api/applications';

  constructor(private http: HttpClient) {}

  list(appID: number): Observable<Version[]> {
    const url = `${this.baseUrl}/${appID}/versions`;
    return this.http.get(url).pipe(
      // Adapt each item in the raw data array
      map((response: VersionsResponse) =>
        response.data.versions.map(Version.adapt)
      )
    );
  }

  listallgroup(appID: number, groupID: number): Observable<Version[]> {
    const url = `${this.baseUrl}/${appID}/versions?versiongroup=${groupID}`;
    return this.http.get(url).pipe(
      // Adapt each item in the raw data array
      map((response: VersionsResponse) =>
        response.data.versions.map(Version.adapt)
      )
    );
  }

  listgroups(appID: number): Observable<VersionGroup[]> {
    const url = `${this.baseUrl}/${appID}/versiongroups`;
    // this.http.get(url).subscribe(
    //     response => console.log('VersionGroups: ' + JSON.stringify(response)));
    return this.http.get(url).pipe(
      // Adapt each item in the raw data array
      map((response: VersionGroupsResponse) =>
        response.data.versionGroups.map(VersionGroup.adapt)
      )
    );
  }

  editgroup(
    appID: number,
    versionGroup: VersionGroup
  ): Observable<VersionGroup> {
    const url = `${this.baseUrl}/${appID}/versiongroups`;

    return this.http.put(url, versionGroup).pipe(
      // Adapt each item in the raw data array
      map((response: VersionGroupResponse) => VersionGroup.adapt(response.data))
    );
  }
}
