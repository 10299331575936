import { ResponseInterface } from './types';

export class Log {
  constructor(
    public logId: number,
    public logName: string,
    public logDate: Date,
    public ipAddress: string,
    public userId: number,
    public userEmail: string,
    public userName: string,
    public targetUserId: number,
    public targetUserEmail: string,
    public targetUserName: string,
    public applicationId: number,
    public applicationName: string,
    public field1Name: string,
    public field1Value: number,
    public details: string,
    public request: string
  ) {}

  static adapt(item: any): Log {
    return new Log(
      item.logId,
      item.logName,
      new Date(item.logDate),
      item.ipAddress,
      item.userId,
      item.userEmail,
      item.userName,
      item.targetUserId,
      item.targetUserEmail,
      item.targetUserName,
      item.applicationId,
      item.applicationName,
      item.field1Name,
      item.field1Value,
      item.details,
      item.request
    );
  }
}

export class LogsData {
  constructor(public logs: Log[]) {}
}

export class LogsResponse implements ResponseInterface {
  status = '';
  data: LogsData;
  constructor() {}
}

export class LogCounters {
  constructor(
    public startUpTime: Date,
    public gitVersion: string,
    public httpRequests: number,
    public userForbids: number,
    public actionForbids: number,
    public actionErrors: number,
    public actionSuccess: number,
    public filesDownloaded: number,
    public filesUploaded: number,
    public licensesCreated: number,
    public eventsLogged: number,
    public emailBatchesSent: number,
    public emailBatchesFailed: number,
    public demosIssued: number,
    public tasksQueued: number,
    public tasksCompleted: number,
    public serverTime: Date,
    public workingSet: number,
    public diskFreeSpace: number
  ) {}

  static adapt(item: any): LogCounters {
    return new LogCounters(
      new Date(item.startUpTime),
      item.gitVersion,
      item.httpRequests,
      item.userForbids,
      item.actionForbids,
      item.actionErrors,
      item.actionSuccess,
      item.filesDownloaded,
      item.filesUploaded,
      item.licensesCreated,
      item.eventsLogged,
      item.emailBatchesSent,
      item.emailBatchesFailed,
      item.demosIssued,
      item.tasksQueued,
      item.tasksCompleted,
      new Date(item.serverTime),
      item.workingSet,
      item.diskFreeSpace
    );
  }
}

export class LogCountersResponse implements ResponseInterface {
  status = '';
  data: LogCounters;
  constructor() {}
}
