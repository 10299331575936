import { Location } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppState, ROUTE_ANIMATIONS_ELEMENTS } from '~core/core.module';
import { selectUserId } from '../../../core/auth/store/auth.selectors';

@Component({
  selector: 'idl-user-edit-page',
  template: `
    <div class="container" rtl>
      <div class="row">
        <div class="col" [ngClass]="routeAnimationsElements">
          <h1 class="main-heading">
            <div *ngIf="addUser; else elseBlock">
              {{ 'idl.users.user.add.title' | translate }}
            </div>
            <ng-template #elseBlock>{{
              'idl.users.user.edit.title' | translate
            }}</ng-template>
          </h1>
        </div>
      </div>
      <br />
      <div class="row">
        <mat-card class="col-md-8" [ngClass]="routeAnimationsElements">
          <mat-card-content>
            <idl-user-edit
              #child
              [addUser]="addUser"
              [userId]="userId"
              [editSelf]="editSelf"
              [saveEvent]="saveSubject"
              (saved)="saved($event)"
            ></idl-user-edit>
          </mat-card-content>
          <mat-card-actions align="end">
            <button
              mat-raised-button
              (click)="submit()"
              [disabled]="child.userForm.invalid"
              color="accent"
            >
              <div *ngIf="addUser; else buttonElseBlock">Create</div>
              <ng-template #buttonElseBlock>Save</ng-template>
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  `,
  styles: [
    '.container { margin-top: 20px; }',
    '.main-heading { text-transform: uppercase; margin: 0 0 20px 0; }'
  ]
})
export class UserEditPageComponent implements OnDestroy {
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  private unsubscribe$ = new Subject<void>();

  @Input() addUser: boolean;
  @Input() enableBack: boolean;

  userId: number;
  editSelf = false;

  public saveSubject: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private _router: Router,
    private store: Store<AppState>,
    private location: Location
  ) {
    this.userId = 0;
    this.enableBack = JSON.parse(
      this.route.snapshot.paramMap.get('enableBack')
    );

    this.route.data.pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
      this.addUser = data.addUser;

      if (!this.addUser) {
        this.route.paramMap
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((params: ParamMap) => {
            if (params.get('id')) {
              this.userId = +params.get('id');
            } else {
              this.store
                .pipe(select(selectUserId))
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((id: string) => {
                  this.userId = +id;
                  this.editSelf = true;
                });
            }
          });
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  back() {
    this.location.back();
    return false;
  }

  submit() {
    this.saveSubject.next();
  }

  saved(user) {
    if (!this.enableBack) this._router.navigate(['/profile', user.userId]);
  }
}
