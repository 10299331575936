<div class="row">
  <div class="col-md-5">
    <mat-form-field appearance="fill" class="full-width" floatLabel="always">
      <mat-label>Version Group</mat-label>
      <mat-select placeholder="Please click here to select a version group." [(ngModel)]="selectedGroup"
        name="versionGroupSelect" (ngModelChange)="onChangeGroup($event)">
        <mat-option disabled>Please click here to select a version group.</mat-option>
        <mat-option [value]="versiongroup" *ngFor="let versiongroup of versiongroups">
          {{versiongroup.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="col-md-3" *idlIsAppRole="[application.applicationId, 'Developer']">
    <button class="mx-1" mat-raised-button color="accent" *ngIf="edit" (click)="addNew()">
      Add New Group
    </button>
    <button class="mx-1" mat-raised-button color="accent" *ngIf="edit && selectedGroup" (click)="editGroup()">
      Edit Group
    </button>
  </div>
</div>

<div class="row" *idlIsAppRole="[application.applicationId, 'Developer']">
  <div class="col-md-3 my-1">
    <button mat-raised-button color="accent" *ngIf="edit" [disabled]="!selectedGroup" (click)="toggleAddFile()">
      Add Version To Group
    </button>
  </div>
</div>

<div *ngIf="addFile">
  <form name="UploadVersion" (ngSubmit)="onSubmit()" *idlIsAppRole="[application.applicationId, 'Developer']">
    <div class="row">
      <div class="col-md-12 my-1">
        <mat-checkbox name="noNotifications" [(ngModel)]="newFile.noNotifications">Blocks notification email to
          license
          holders.</mat-checkbox>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 my-1">
        <mat-checkbox [disabled]="newFile.noNotifications" name="importantUpdate" [(ngModel)]="newFile.importantUpdate">
          Important Update (emails all subscribed users)
        </mat-checkbox>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 my-1">
        <input type="file" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01"
          (change)="onFileChange($event.target.files)">
        <label style="display: none" class="custom-file-label" #labelImport for="inputGroupFile01">Choose file</label>
      </div>
    </div>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Version</mat-label>
      <input matInput name="version" placeholder="1.0.0.0" type="text" [(ngModel)]="newFile.version">
    </mat-form-field>

    <mat-checkbox name="overrideReleaseDate" [(ngModel)]="overrideReleaseDate">Override Release Date</mat-checkbox>

    <mat-form-field *ngIf="overrideReleaseDate" appearance="fill" class="full-width">
      <mat-label>Release Date</mat-label>
      <input matInput [matDatepicker]="picker" placeholder="Release Date" name="uploadDate" [(ngModel)]="overrideDate">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Release Notes</mat-label>
      <textarea matInput name="comments" placeholder="Release Notes" cols="40" rows="3" [(ngModel)]="newFile.comments">
          </textarea>
    </mat-form-field>

    <div class="row">
      <div class="col-md-3">
        <button [disabled]="!selectedGroup || !fileToUpload" mat-raised-button color="accent"
          type="submit">Upload</button>
      </div>
    </div>
  </form>
</div>

<div class="row">
  <div class="col-12">
    <mat-list class="version-list">
      <mat-list-item class="version-list-item" *ngFor="let version of versions">
        <idl-version [version]="version" [showEdit]="appDeveloper && edit" [downloadLocked]="downloadingFile"
          (download)="downloadVersion(version)" (edit)="editVersion(version)">
        </idl-version>
        <mat-divider></mat-divider>
      </mat-list-item>
    </mat-list>
  </div>
</div>