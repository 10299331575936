import { Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroupDirective, Validators } from '@angular/forms';
import { NbRoleProvider } from '@nebular/security';
import { forkJoin, Observable, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ROUTE_ANIMATIONS_ELEMENTS } from '~core/core.module';
import { AuthService } from '../../../core/data/auth.service';
import { OrganisationService } from '../../../core/data/organisation.service';
import { UsersService } from '../../../core/data/users.service';
import { Organisation } from '../../../core/models/organistion.model';
import { Role, Roles } from '../../../core/models/roles.model';
import { User } from '../../../core/models/user.model';
import { EnumEx } from '../../../core/utils/enum-ex.service';
import { MustMatch } from '../../../core/utils/must-match.validator';
import { SnackbarQueueService } from '../../../core/utils/snackbar-queue/snackbar-queue.service';

@Component({
  selector: 'idl-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;

  @Input() addUser: boolean;
  @Input() userId: number;
  @Input() editSelf;

  user: User;
  roles: Role[];
  existingRoles: string[] = [];
  submitted = false;
  organisations: Organisation[];
  /** list of orgs filtered by search keyword */
  public filteredOrganisations: ReplaySubject<
    Organisation[]
  > = new ReplaySubject<Organisation[]>(1);

  @Output() saved: EventEmitter<any> = new EventEmitter();
  @Input() saveEvent: Observable<void>;

  userForm = this._fb.group({
    email: ['', [Validators.required, Validators.email]],
    firstName: ['', Validators.required],
    middleName: [''],
    lastName: ['', Validators.required],
    comments: [''],
    crmLink: [''],
    organisationId: [''],
    suppressEmail: [false],
    roles: [[]],
    lockedOut: [false]
  });

  passwordForm = this._fb.group(
    {
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirm: ['', Validators.required]
    },
    {
      validator: MustMatch('password', 'confirm')
    }
  );

  constructor(
    private _authService: AuthService,
    private usersService: UsersService,
    private _fb: FormBuilder,
    private roleProvider: NbRoleProvider,
    private location: Location,
    private _organisationService: OrganisationService,
    private _snackBar: SnackbarQueueService
  ) {
    this.roleProvider
      .getRole()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(userroles => {
        this.roles = this.getRoles(userroles);

        // Super User cant edit organisation
        if (
          (userroles.includes(Roles[Roles.Administrator]) ||
            userroles.includes(Roles[Roles.Manager])) &&
          !userroles.includes(Roles[Roles.SuperUser])
        ) {
          this._organisationService
            .list()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(orgs => {
              this.organisations = orgs;
              this.filteredOrganisations.next(this.organisations);
            });
        }
      });

    this.user = new User();
    this.user.userId = 0;
  }

  ngOnInit(): void {
    this.saveEvent
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.saveUser());

    if (!this.addUser) {
      if (this.userId && this.userId !== 0) {
        this.usersService
          .get(this.userId)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(user => {
            // console.log(user);
            this.user = user;

            this.userForm.patchValue({
              email: this.user.email,
              firstName: this.user.firstName,
              middleName: this.user.middleName,
              lastName: this.user.lastName,
              comments: this.user.comments,
              crmLink: this.user.crmLink,
              organisationId: this.user.organisationId,
              suppressEmail: this.user.suppressEmail,
              lockedOut: this.user.lockedOut
            });

            this.usersService
              .listroles(this.userId)
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe(roles => {
                this.existingRoles = roles;

                this.userForm.patchValue({
                  roles: this.existingRoles
                });
              });
          });
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  back() {
    this.location.back();
    return false;
  }

  /**
   * Gets valid roles array that editing user can apply
   * @param userRoles Array of roles that the editor holds
   * @returns Array of roles that can be edited
   */
  getRoles(userRoles: string | string[]) {
    const roles: Role[] = [];

    // Get name-value pairs from ProductTypeEnum
    const typeEnumList = EnumEx.getNamesAndValues(Roles);

    // Convert name-value pairs to ProductType[]
    typeEnumList.forEach(pair => {
      const role = { id: pair.value, name: pair.name };
      roles.push(role);
    });

    // Only SU can edit SU and Admin
    if (!userRoles.includes(Roles[Roles.SuperUser])) {
      let roleIndex = roles.findIndex(o => o.name === Roles[Roles.SuperUser]);
      if (roleIndex >= 0) {
        roles.splice(roleIndex, 1);
      }
      roleIndex = roles.findIndex(o => o.name === Roles[Roles.Administrator]);
      if (roleIndex >= 0) {
        roles.splice(roleIndex, 1);
      }
    }

    // Only Admin can create Manager and Developers
    if (!userRoles.includes(Roles[Roles.Administrator])) {
      let roleIndex = roles.findIndex(o => o.name === Roles[Roles.Manager]);
      if (roleIndex >= 0) {
        roles.splice(roleIndex, 1);
      }
      roleIndex = roles.findIndex(o => o.name === Roles[Roles.Developer]);
      if (roleIndex >= 0) {
        roles.splice(roleIndex, 1);
      }
    }

    // No one can add Licenseholder
    const index = roles.findIndex(o => o.name === Roles[Roles.LicenseHolder]);
    if (index >= 0) {
      roles.splice(index, 1);
    }

    return roles;
  }

  filterOrg(event) {
    if (!this.organisations) {
      return;
    }
    // get the search keyword
    let search = event;
    if (!search) {
      this.filteredOrganisations.next(this.organisations);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the list
    this.filteredOrganisations.next(
      this.organisations.filter(org => org.name.toLowerCase().includes(search))
    );
  }

  updatePassword(formDirective: FormGroupDirective) {
    this.usersService
      .setpassword(this.passwordForm.value.password)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        () => {
          this._snackBar.show('New password saved.', '', {
            duration: 2000,
            verticalPosition: 'top'
          });
          formDirective.resetForm();
          this.passwordForm.reset();
          // this.passwordForm.value.password = '';
          // this.passwordForm.value.confirm = '';
          // this.passwordForm.markAsUntouched();
          // this.passwordForm.markAsPristine();
        },
        err => {
          this._snackBar.show('Error saving password.', '', {
            duration: 2000,
            verticalPosition: 'top'
          });
        }
      );
  }

  saveUser() {
    this.submitted = true;

    this.user.email = this.userForm.value.email;
    this.user.firstName = this.userForm.value.firstName;
    this.user.middleName = this.userForm.value.middleName;
    this.user.lastName = this.userForm.value.lastName;
    this.user.comments = this.userForm.value.comments;
    this.user.crmLink = this.userForm.value.crmLink;
    this.user.organisationId = this.userForm.value.organisationId;
    this.user.suppressEmail = this.userForm.value.suppressEmail;
    this.user.lockedOut = this.userForm.value.lockedOut;

    const userEdits: Observable<any>[] = [];

    this.usersService
      .edit(this.user)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        user => {
          if (this.addUser) {
            this._snackBar.show('New user successfully created.', 'New User', {
              duration: 2000,
              verticalPosition: 'top'
            });
          } else {
            this._snackBar.show('Profile successfully saved.', 'Saved', {
              duration: 2000,
              verticalPosition: 'top'
            });
          }

          // if (this.addUser && (!user.passwordHash || user.passwordHash === '')) {
          //   // No password set so send new user email
          //   this._authService.resetrequest(user.email)
          //     .pipe(take(1))
          //     .subscribe(
          //       () => { },
          //     );
          // }

          this.roleProvider
            .getRole()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(response => {
              if (
                response.includes(Roles[Roles.SuperUser]) ||
                response.includes(Roles[Roles.Administrator])
              ) {
                // Only admin and SU edit roles here
                // Checked delete Roles first
                // Also dont delete license holder role
                // Only SU can edit SU and Admin
                const index = this.existingRoles.findIndex(
                  o => o === Roles[Roles.LicenseHolder]
                );
                if (index >= 0) {
                  this.existingRoles.splice(index, 1);
                }

                const deleteRole = this.existingRoles.filter(
                  item =>
                    this.userForm.value.roles.findIndex(i => i === item) < 0
                );
                const addRole = this.userForm.value.roles.filter(
                  item => this.existingRoles.findIndex(i => i === item) < 0
                );

                deleteRole.forEach(role => {
                  userEdits.push(
                    this.usersService.deleterole(user.userId, role)
                  );
                });

                // Check Adding roles
                addRole.forEach(role => {
                  userEdits.push(this.usersService.addrole(user.userId, role));
                });
              }

              if (userEdits.length > 0) {
                forkJoin(userEdits).subscribe(res => {
                  this.submitted = false;

                  this._snackBar.show('User roles updated.', '', {
                    duration: 2000,
                    verticalPosition: 'top'
                  });

                  this.saved.emit(user);
                });
              } else {
                this.submitted = false;
                this.saved.emit(user);
              }
            });
        },
        err => {
          this._snackBar.show('Error saving profile.', err, {
            duration: 5000,
            verticalPosition: 'top'
          });
        }
      );
  }
}
