import { ResponseInterface } from './types';

export class VersionGroup {
  constructor(applicationId: number, name: string);
  constructor(
    applicationId: number,
    name: string,
    versionGroupId: number,
    applicationName: string,
    fileCount: number,
    creationdate: Date,
    creationuserid: number,
    startDate: Date,
    excludeFromVersionFilter: boolean,
    releaseProcessing: string
  );
  constructor(
    public applicationId?: number,
    public name?: string,
    public versionGroupId?: number,
    public applicationName?: string,
    public fileCount?: number,
    public creationdate?: Date,
    public creationuserid?: number,
    public startDate?: Date,
    public excludeFromVersionFilter?: boolean,
    public releaseProcessing?: string
  ) {}

  static adapt(item: any): VersionGroup {
    return new VersionGroup(
      item.applicationId,
      item.name,
      item.versionGroupId,
      item.applicationName,
      item.fileCount,
      new Date(item.creationDate),
      item.createdByUserId,
      new Date(item.startDate),
      item.excludeFromVersionFilter,
      item.releaseProcessing
    );
  }
}

export class VersionGroupsData {
  constructor(public versionGroups: VersionGroup[]) {}
}

export class VersionGroupResponse implements ResponseInterface {
  status = '';
  data: VersionGroup;
  constructor() {}
}

export class VersionGroupsResponse implements ResponseInterface {
  status = '';
  data: VersionGroupsData;
  constructor() {}
}
