import { Injectable } from '@angular/core';

@Injectable()
export class EnumEx {
  private constructor() {}

  public static getNamesAndValues<T extends number>(e: any) {
    return EnumEx.getNames(e).map(n => ({ name: n, value: e[n] as T }));
  }

  public static getNames(e: any) {
    return Object.keys(e).filter(k => typeof e[k] === 'number') as string[];
  }

  public static getValues<T extends number>(e: any) {
    return Object.keys(e)
      .map(k => e[k])
      .filter(v => typeof v === 'number') as T[];
  }
}
