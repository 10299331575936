<div [class]="'theme-wrapper ' + (theme$ | async)">

  <div class="toolbar" [style.position]="(stickyHeader$ | async) ? 'fixed' : 'inherit'"
    [class.mat-elevation-z4]="(stickyHeader$ | async)">
    <mat-toolbar color="primary">
      <button mat-icon-button class="d-md-none" (click)="sidenav.open()">
        <mat-icon>
          menu
        </mat-icon>

      </button>

      <span routerLink="" class="branding spacer center d-inline d-sm-none">
        <img [src]="logo" /></span>
      <span routerLink="" class="branding spacer center d-none d-sm-inline d-md-none"><img [src]="logo" /> {{
                  'ait.title.short' | translate }}</span>
      <span routerLink="" class="branding spacer d-none d-md-inline"><img [src]="logo" /> {{ 'ait.title.long' | translate
                  }}</span>

      <button mat-button mat-raised-button color="accent" (click)="onLoginClick()" *ngIf="!authLocal.loggedIn">
        {{ 'ait.menu.login' | translate }}
      </button>

      <button *ngIf="authLocal.loggedIn" mat-icon-button class="user-picture" [matMenuTriggerFor]="toolbarUserMenu">
        <img appAvatar [url]="(userPicture$ | async)">
      </button>

      <!-- class="d-none d-sm-inline" in menu will means it disappears from mobile UI -->
      <mat-menu #toolbarUserMenu="matMenu">
        <button mat-menu-item routerLink="settings">
          <mat-icon>
            settings
          </mat-icon>
          <span>{{ 'idl.menu.settings' | translate }}</span>
        </button>
        <button mat-menu-item (click)="onLogoutClick()">
          <mat-icon>
            power_settings_new
          </mat-icon>
          <span>{{ 'ait.menu.logout' | translate }}</span>
        </button>
      </mat-menu>

      <span *ngIf="!authLocal.loggedIn && language$ | async as language">
        <mat-select [ngModel]="language" (selectionChange)="onLanguageSelect($event)">
          <mat-option *ngFor="let l of languages" [value]="l">
            {{ l.toUpperCase() }}
          </mat-option>
        </mat-select>
      </span>
    </mat-toolbar>
  </div>

  <mat-sidenav-container>

    <mat-sidenav #sidenav [opened]="screenWidth > 768" [mode]="(screenWidth > 768) ? 'side' : 'over'"
      [disableClose]="screenWidth > 768">
      <div class="branding"><img [src]="logo" />
        <span>{{ 'ait.title.short' | translate}}</span></div>
      <mat-nav-list>
        <ng-container *ngFor="let item of navigationSideMenu">
          <a mat-list-item *ngIf="!item.hidden" (click)="screenWidth <= 768 && sidenav.close()"
            [routerLink]="[item.link]" routerLinkActive="active">
            {{item.label | translate}}
          </a>
        </ng-container>
      </mat-nav-list>
    </mat-sidenav>

    <div class="wrapper">

      <div class="content"
        [@routeAnimations]="o.isActivated && o.activatedRoute.routeConfig.data && o.activatedRoute.routeConfig.data.title">
        <router-outlet #o="outlet"></router-outlet>
      </div>

      <div class="footer">
        <div class="row">
          <div class="col-sm-12 address">
            <strong>InterDynamics Head Office</strong><br>
            320 Adelaide Street, Brisbane<br>
            Queensland Australia 4000<br>
            Tel: +61 7 3229 8300
          </div>
        </div>
        <div class="row">
          <div class="col-12 signature">
            &#169; <span class="year">{{year}}</span> - InterDynamics
            <br class="d-block d-sm-none">
            <a [matTooltip]="'ait.footer.changelog' | translate" matTooltipPosition="before"
              href="http://tasks.interdynamics.com/changelog_page.php">
              {{version}} <span *ngIf="!isProd">[{{envName}}]</span>
            </a>
          </div>
        </div>
      </div>

    </div>

  </mat-sidenav-container>

</div>