import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable } from '@angular/core';
import * as Rollbar from 'rollbar';
import { environment } from '../../../environments/environment';
import { NotificationService } from '../notifications/notification.service';
import { RollbarService } from './rollbar.module';

/** Application-wide error handler that adds a UI notification to the error handling
 * provided by the default Angular ErrorHandler.
 */
@Injectable()
export class AppErrorHandler extends ErrorHandler {
  constructor(
    @Inject(RollbarService) private rollbar: Rollbar,
    private notificationsService: NotificationService
  ) {
    super();
  }

  handleError(error: Error | HttpErrorResponse) {
    let displayMessage = 'An error occurred.';

    if (!environment.production) {
      displayMessage += ' See console for details.';
    } else {
      this.rollbar.error(error);
    }

    this.notificationsService.error(displayMessage);

    super.handleError(error);
  }
}
