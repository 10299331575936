<h2 mat-dialog-title>Confirm License Details</h2>
<mat-dialog-content fxLayout="column">

  <mat-form-field floatLabel="always">
    <mat-label>User</mat-label>
    <input matInput readonly placeholder="User" [value]="dialogData.user.fullName"
      name="user">
  </mat-form-field>

  <mat-form-field floatLabel="always">
    <mat-label>Email</mat-label>
    <input matInput readonly placeholder="Email" [value]="dialogData.user.email" name="email">
  </mat-form-field>

  <mat-form-field floatLabel="always">
    <mat-label>Organisation</mat-label>
    <input matInput readonly placeholder="Organisation" [value]="dialogData.user.organisationName" name="organisationName">
  </mat-form-field>

  <mat-form-field floatLabel="always">
    <mat-label>License Type</mat-label>
    <input matInput readonly placeholder="License Type" [value]="dialogData.licenseType" name="licenseType">
  </mat-form-field>

  <mat-form-field floatLabel="always">
    <mat-label>Expiry Date</mat-label>
    <input matInput readonly placeholder="Expiry Date" [value]="dialogData.expiryDate|date" name="expiryDate">
  </mat-form-field>

  <mat-form-field floatLabel="always" *ngIf="dialogData.additionalEmails">
    <mat-label>Additional Email</mat-label>
    <input matInput readonly placeholder="Additional Emails" [value]="dialogData.additionalEmails" name="additionalEmails">
  </mat-form-field>

  <mat-form-field *ngIf="dialogData.selectedSet" floatLabel="always">
    <mat-label>Metadata Set</mat-label>
    <input matInput readonly placeholder="Metadata Set" [(ngModel)]="dialogData.selectedSet.name" name="selectedSet">
  </mat-form-field>

  <mat-form-field *ngIf="dialogData.selectedSet" floatLabel="always">
    <mat-label>Set Description</mat-label>
    <textarea rows="5" readonly matInput placeholder="Description" [(ngModel)]="dialogData.selectedSet.comments"
      name="setdescription"></textarea>
  </mat-form-field>

  <mat-form-field floatLabel="always">
    <mat-label>Comments</mat-label>
    <textarea rows="5" readonly matInput placeholder="Comments" [value]="dialogData.comments" name="comments"></textarea>
  </mat-form-field>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-raised-button color="accent" [mat-dialog-close]="true" cdkFocusInitial>Confirm</button>
</mat-dialog-actions>