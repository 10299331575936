import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { License } from '~app/core/models/license.model';
import { NotificationService } from '~core/core.module';
import { FilesService } from '~core/data/files.service';
import { VersionsService } from '~core/data/versions.service';
import { Application } from '~core/models/application.model';
import { VersionGroup } from '~core/models/version-group.model';
import { Version, VersionUpload } from '~core/models/version.model';
import { IdAppRoleChecker } from '~core/utils/app-roles/services/app-role-checker.service';
import { ProgressDialogComponent } from '~core/utils/progress-dialog/progress-dialog.component';
import { DialogGroupPromptComponent } from './dialog-group-prompt/dialog-group-prompt.component';
import { VersionDetailsComponent } from './version-details/version-details.component';

@Component({
  selector: 'idl-versions-content',
  templateUrl: './versions-content.component.html',
  styleUrls: ['./versions-content.component.scss']
})
export class VersionsContentComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  private progress$ = new Subject();

  @Input() application: Application;
  @Input() license: License;
  @Input() edit = false;

  @ViewChild('labelImport', { static: true }) labelImport: ElementRef;

  versiongroups: VersionGroup[];
  versions: Version[];

  addFile = false;
  downloadingFile = false;

  newFile: VersionUpload;
  fileToUpload: File;
  overrideReleaseDate = false;
  overrideDate: Date;

  selectedGroup: VersionGroup = null;

  appDeveloper = false;

  constructor(
    private cdRef: ChangeDetectorRef,
    private versionsService: VersionsService,
    private filesService: FilesService,
    private dialog: MatDialog,
    private idAppRoleChecker: IdAppRoleChecker,
    private readonly _notificationService: NotificationService
  ) {
    this.newFile = new VersionUpload();
    this.newFile.importantUpdate = false;
    this.newFile.noNotifications = false;
    this.newFile.comments = '';
  }

  ngOnInit() {
    this.idAppRoleChecker
      .isGranted(this.application.applicationId, 'Developer')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(res => {
        this.appDeveloper = res;
      });
    this.versionsService
      .listgroups(this.application.applicationId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(versiongroups => {
        // console.log(versiongroups);
        this.versiongroups = versiongroups;
        this.versiongroups.sort(
          (b, a) => a.startDate.getTime() - b.startDate.getTime()
        );

        if (this.versiongroups != null && this.versiongroups.length > 0) {
          this.selectedGroup = this.versiongroups[0];
          this.versionsService
            .listallgroup(
              this.application.applicationId,
              this.versiongroups[0].versionGroupId
            )
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(versions => {
              this.versions = versions;
              this.versions.sort(
                (b, a) => a.uploadDate.getTime() - b.uploadDate.getTime()
              );
            });
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onChangeGroup(event) {
    this.selectedGroup = event;
    this.loadVersions();
  }

  loadVersions() {
    this.versionsService
      .listallgroup(
        this.application.applicationId,
        this.selectedGroup.versionGroupId
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        // console.log(data);
        this.versions = data;
        this.versions.sort(
          (b, a) => a.uploadDate.getTime() - b.uploadDate.getTime()
        );
      });
  }

  addNew() {
    const dialogRef = this.dialog.open(DialogGroupPromptComponent, {
      width: '350px'
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((newGroup: VersionGroup) => {
        if (newGroup) {
          newGroup.applicationId = this.application.applicationId;
          this.versionsService
            .editgroup(this.application.applicationId, newGroup)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              data => {
                this._notificationService.success(
                  'New version group successfully created.'
                );

                this.versiongroups.push(data);
              },
              err => {
                this._notificationService.error('Error Creating Group.');
              }
            );
        }
      });
  }

  editGroup() {
    const dialogRef = this.dialog.open(DialogGroupPromptComponent, {
      width: '350px',
      data: { editGroup: this.selectedGroup }
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((newGroup: VersionGroup) => {
        if (newGroup) {
          newGroup.applicationId = this.application.applicationId;
          this.versionsService
            .editgroup(this.application.applicationId, newGroup)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
              data => {
                this._notificationService.success(
                  'Version group successfully Edited.'
                );
              },
              err => {
                this._notificationService.error(
                  'Error Editing Group: ' + err.statusText
                );
              }
            );
        }
      });
  }

  toggleAddFile() {
    this.addFile = !this.addFile;
  }

  onFileChange(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  onSubmit() {
    this.newFile.overrideDate = null;
    if (this.overrideReleaseDate) {
      this.newFile.overrideDate = this.overrideDate;
    }
    this.progress$.next(0);
    const dialogRef = this.dialog.open(ProgressDialogComponent, {
      maxWidth: '650px',
      width: '90%',
      disableClose: true,
      data: {
        message: 'Uploading ' + this.fileToUpload.name,
        progress$: this.progress$
      }
    });

    dialogRef.afterOpened().subscribe(() => {
      this.filesService
        .addVersion(
          this.fileToUpload,
          this.newFile,
          this.selectedGroup.versionGroupId
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          event => {
            if (event.progress === 100 && event.version) {
              dialogRef.close();
              this._notificationService.success(
                'New version successfully uploaded.'
              );

              // Hide add file form
              this.addFile = !this.addFile;

              // add new version to versions list
              this.versions.unshift(event.version);
            } else {
              this.progress$.next(event.progress);
            }
          },
          err => {
            dialogRef.close();
            this._notificationService.error(
              'Error Uploading: ' + err.statusText
            );
          }
        );
    });
  }

  downloadVersion(version: Version) {
    this.downloadingFile = true;
    this.cdRef.detectChanges();
    this.progress$.next(0);
    const dialogRef = this.dialog.open(ProgressDialogComponent, {
      maxWidth: '650px',
      width: '90%',
      disableClose: true,
      data: {
        message: 'Downloading ' + version.name,
        progress$: this.progress$
      }
    });

    dialogRef
      .afterOpened()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.filesService
          .download(
            version.fileId,
            this.license ? this.license.licenseRecordId : 0
          )
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            event => {
              if (event.blob) {
                // It is necessary to create a new blob object with mime-type explicitly set
                // otherwise only Chrome works like it should
                const newBlob = new Blob([event.blob], { type: event.type });

                dialogRef.close();

                // IE doesn't allow using a blob object directly as link href
                // instead it is necessary to use msSaveOrOpenBlob
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                  window.navigator.msSaveOrOpenBlob(newBlob, version.name);
                  return;
                }

                // For other browsers:
                // Create a link pointing to the ObjectURL containing the blob.
                const data = window.URL.createObjectURL(newBlob);

                const link = document.createElement('a');
                link.href = data;
                link.download = version.name;
                // this is necessary as link.click() does not work on the latest firefox
                link.dispatchEvent(
                  new MouseEvent('click', {
                    bubbles: true,
                    cancelable: true,
                    view: window
                  })
                );

                setTimeout(function() {
                  // For Firefox it is necessary to delay revoking the ObjectURL
                  window.URL.revokeObjectURL(data);
                  link.remove();
                }, 100);
                this.downloadingFile = false;
                this.cdRef.detectChanges();
              } else {
                this.progress$.next(event.progress);
              }
            },
            err => {
              this.downloadingFile = false;
              dialogRef.close();
              this.cdRef.detectChanges();
            }
          );
      });
  }

  editVersion(version: Version) {
    const dialogRef = this.dialog.open(VersionDetailsComponent, {
      width: '400px',
      data: { version: version }
    });

    dialogRef.afterClosed().subscribe(newversion => {
      if (newversion === 'save') {
        version.overrideDate = version.uploadDate;
        this.filesService
          .edit(version.fileId, version)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            res => {
              this._notificationService.success('Version successfully edited.');
            },
            err => {
              this._notificationService.error(
                'Error saving edit: ' + err.statusText
              );
            }
          );
      } else if (newversion === 'delete') {
        this.filesService
          .delete(version.fileId)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            res => {
              const index = this.versions.findIndex(
                o => o.fileId === version.fileId
              );
              if (index >= 0) {
                this.versions.splice(index, 1);
              }
              this._notificationService.success('Version deleted.');
            },
            err => {
              this._notificationService.error(
                'Error deleting version: ' + err.statusText
              );
            }
          );
      }
    });
  }
}
