import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import {
  MetadataDefinition,
  MetadataDefinitionResponse,
  MetadataDefinitionsResponse
} from '../models/metadata-definition.model';

@Injectable({
  providedIn: 'root'
})
export class MetadataDefinitionService {
  private baseUrl = 'api/applications';

  constructor(private http: HttpClient) {}

  listall(appID: number): Observable<MetadataDefinition[]> {
    const url = `${this.baseUrl}/${appID}/metadatadefinitions`;
    return this.http.get(url).pipe(
      first(),
      // Adapt each item in the raw data array
      map((response: MetadataDefinitionsResponse) =>
        response.data.metadataDefinitions.map(MetadataDefinition.adapt)
      )
    );
  }

  edit(appID: number, mdd: MetadataDefinition): Observable<MetadataDefinition> {
    const url = `${this.baseUrl}/${appID}/metadatadefinitions`;
    return this.http.put(url, mdd).pipe(
      // Adapt each item in the raw data array
      map((response: MetadataDefinitionResponse) =>
        MetadataDefinition.adapt(response.data)
      )
    );
  }

  delete(appID: number, mID: number): Observable<Object> {
    const url = `${this.baseUrl}/${appID}/metadatadefinitions/${mID}`;
    return this.http.delete(url);
  }
}
