import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import {
  LicenseGenMetadata,
  MetadataOverride
} from '~core/models/metadata.model';
import { TableDataSource } from '~core/utils/material-table/table-data-source';

@Component({
  selector: 'idl-meta-data-values',
  templateUrl: './meta-data-values.component.html',
  styleUrls: ['./meta-data-values.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MetaDataValuesComponent implements OnInit {
  displayedColumns: string[] = ['definitionName', 'newval', 'actionsColumn'];
  @Input() dataSource: TableDataSource<LicenseGenMetadata>;

  @Input() overrideMetaData: MetadataOverride[] = [];

  activeMetaData: LicenseGenMetadata[] = [];

  constructor() {}

  ngOnInit() {}

  createOrEditConfirm(row) {
    // row.currentData.newval = row.currentData.newval.toString();
    if (
      row.currentData.value &&
      row.currentData.value.toString() === row.currentData.newval.toString()
    ) {
      row.currentData.override = 0;
      row.currentData.newval = null;
      const index = this.overrideMetaData.findIndex(
        o => o.metadataDefinitionId === row.currentData.metadataDefinitionId
      );
      if (index >= 0) {
        this.overrideMetaData.splice(index, 1);
      }
    } else {
      row.currentData.override = 1;
      const index = this.overrideMetaData.findIndex(
        o => o.metadataDefinitionId === row.currentData.metadataDefinitionId
      );
      if (index >= 0) {
        this.overrideMetaData[index].value = row.currentData.newval;
      } else {
        this.overrideMetaData.push(
          new MetadataOverride(
            row.currentData.metadataDefinitionId,
            row.currentData.newval
          )
        );
      }
    }

    row.confirmEditCreate();
  }
}
