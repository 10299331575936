import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MetadataSet } from '~core/models/metadata-set.model';
import { UserSummary } from '~core/models/user-summary.model';

export interface DialogData {
  user: UserSummary;
  licenseType: string;
  expiryDate: Date;
  selectedSet: MetadataSet;
  comments: string;
  additionalEmails?: string;
}

@Component({
  selector: 'idl-confirm-license',
  templateUrl: './confirm-license.component.html',
  styleUrls: ['./confirm-license.component.scss']
})
export class ConfirmLicenseComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: DialogData) {}

  ngOnInit() {}
}
