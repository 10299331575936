import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'idl-license-history-dialog',
  template: `
    <h2 mat-dialog-title>License History</h2>
    <mat-dialog-content class="mat-typography">
      <idl-license-history [licenseId]="licenseID"></idl-license-history>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button
        class="dialog-button"
        mat-stroked-button
        color="accent"
        mat-dialog-close
      >
        Close
      </button>
    </mat-dialog-actions>
  `,
  styles: [
    '.mat-dialog-content { height: 100%!important;}',
    '.dialog-button { margin: 0 5px;}'
  ]
})
export class LicenseHistoryDialogComponent {
  @Input() licenseID: number;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: number) {
    if (dialogData) {
      this.licenseID = dialogData;
    }
  }
}
