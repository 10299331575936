import { PlatformModule } from '@angular/cdk/platform';
import { NgModule } from '@angular/core';
import {
  DateAdapter,
  DateAdapter as MaterialDateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core';
import {
  DayjsDateAdapter,
  MAT_DAYJS_DATE_ADAPTER_OPTIONS
} from './dayjs-date-adapter';
import { MAT_DAYJS_DATETIME_FORMATS } from './dayjs-datetime-formats';

@NgModule({
  imports: [PlatformModule],
  providers: [
    {
      provide: DateAdapter,
      useClass: DayjsDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_DAYJS_DATE_ADAPTER_OPTIONS]
    },
    { provide: MaterialDateAdapter, useClass: DayjsDateAdapter }
  ]
})
export class DayjsDateModule {}

@NgModule({
  imports: [DayjsDateModule],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MAT_DAYJS_DATETIME_FORMATS }
  ]
})
export class MatDayjsDateModule {}
