import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SetPassword } from '../models/set-password.model';
import {
  VerifyReset,
  VerifyResetResponse
} from '../models/verify-reset-response.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private baseUrl = 'api/auth';

  constructor(private http: HttpClient) {}

  resetrequest(email: string): Observable<Object> {
    const url = `${this.baseUrl}/resetrequest`;
    const request = { email: email };
    return this.http.put(url, request);
  }

  verifyreset(
    token: string,
    email: string,
    termsAccepted: boolean
  ): Observable<VerifyReset> {
    const url = `${this.baseUrl}/verifyreset`;
    const request = new SetPassword(email, token, '', termsAccepted);
    return this.http
      .put(url, request)
      .pipe(
        map((response: VerifyResetResponse) => VerifyReset.adapt(response.data))
      );
  }

  resetpassword(
    token: string,
    email: string,
    password: string,
    termsAccepted: boolean
  ): Observable<Object> {
    const url = `${this.baseUrl}/resetpassword`;
    const request = new SetPassword(email, token, password, termsAccepted);
    return this.http.put(url, request);
  }
}
