import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApplicationLicenseService } from '../../core/data/application-license.service';

@Component({
  selector: 'idl-license-appname',
  template: `
    <div *ngIf="rowData.applicationId" class="app-name">
      {{ rowData.applicationName }}
    </div>
    <div *ngIf="appVersion" class="app-version">
      Latest Version: {{ appVersion }}
    </div>
    <div *ngIf="availableVersion" class="app-available">
      Available Version: {{ availableVersion }}
    </div>
  `,
  styles: [
    '.name-container { position: relative; display: flex; flex-direction: column; }',
    '.app-name { vertical-align: top; flex-grow: 1; font-weight: 500; }',
    '.app-version { vertical-align: bottom; flex-grow: 0; font-weight: 300; font-size: 12px; }',
    '.app-available { vertical-align: bottom; flex-grow: 0; font-weight: 300; font-size: 12px; }'
  ]
})
export class LicenseAppNameComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  renderValue: string;
  appVersion: string;
  availableVersion: string;

  @Input() rowData: any;

  constructor(
    private appLicenseService: ApplicationLicenseService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.appLicenseService
      .latestVersion(this.rowData.applicationId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(appVersion => {
        if (appVersion.length > 0) {
          this.appVersion = appVersion[appVersion.length - 1].version;
          this.appLicenseService
            .latestVersion(
              this.rowData.applicationId,
              this.rowData.licenseRecordId
            )
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(availVersion => {
              if (availVersion.length > 0) {
                if (
                  this.appVersion !==
                  availVersion[availVersion.length - 1].version
                )
                  this.availableVersion =
                    availVersion[availVersion.length - 1].version;
              }
              this.cd.detectChanges();
            });
          this.cd.detectChanges();
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
