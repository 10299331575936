import { MetadataType } from './metadata-definition.model';
import { ResponseInterface } from './types';

export class Metadata {
  constructor(
    public metadataId: number = 0,
    public metadataDefinitionId: number = 0,
    public metadataSetId: number = 0,
    public definitionName: string = '',
    public definitionType: string = '',
    public value: string = '',
    public definitionValue: string = ''
  ) {}

  static adapt(item: any): Metadata {
    return new Metadata(
      item.metadataId,
      item.metadataDefinitionId,
      item.metadataSetId,
      item.definitionName,
      MetadataType[item.definitionType],
      item.value,
      item.definitionValue
    );
  }
}

export class LicenseGenMetadata extends Metadata {
  constructor(
    public metadataId: number = 0,
    public metadataDefinitionId: number = 0,
    public metadataSetId: number = 0,
    public definitionName: string = '',
    public definitionType: string = '',
    public value: string = '',
    public newval: string = '',
    public override: number = 0
  ) {
    super(
      metadataId,
      metadataDefinitionId,
      metadataSetId,
      definitionName,
      definitionType,
      value,
      null
    );
  }
}

export class MetadataData {
  constructor(public metadata: Metadata[]) {}
}

export class MetadatasResponse implements ResponseInterface {
  status = '';
  data: MetadataData;
  constructor() {}
}

export class MetadataResponse implements ResponseInterface {
  status = '';
  data: Metadata;
  constructor() {}
}

export class MetadataOverride {
  constructor(public metadataDefinitionId: number, public value: string) {}
}
