<mat-card>
  <mat-card-title>
    Users
  </mat-card-title>

  <mat-card-subtitle fxLayout="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
    <mat-form-field class="filter">
      <input matInput (keyup)="applyFilter($event)" placeholder="Filter">
      <mat-hint>Comma seperate filter terms. (Eg. application, username)</mat-hint>
    </mat-form-field>
    <mat-slide-toggle color="accent" [checked]="filterLockedOut" (change)="setLockedOutFilter($event)">Hide Locked
      Out Users</mat-slide-toggle>
  </mat-card-subtitle>

  <button class="header-button" mat-raised-button color="accent" (click)="addUser()">Add</button>

  <mat-card-content>
    <table mat-table [dataSource]="dataSource" matSort>
      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
        <td mat-cell *matCellDef="let element">
          <span class="mobile-label">Email:</span>
          <div class="user-name">{{element.email}}</div>
          <div class="row-actions"><button (click)="onEditClick(element)">Edit</button> | <button
              (click)="onDetailsClick(element)">View Details</button></div>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let element">
          <span class="mobile-label">Name:</span>
          {{element.firstName}} {{element.middleName}} {{element.lastName}}
        </td>
      </ng-container>

      <!-- Organisation Column -->
      <ng-container matColumnDef="organisationName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Organisation </th>
        <td mat-cell *matCellDef="let element">
          <span class="mobile-label">Organisation:</span>
          {{element.organisationName}}
        </td>
      </ng-container>

      <!-- Industry Column -->
      <ng-container matColumnDef="industries">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Industry </th>
        <td mat-cell *matCellDef="let element">
          <span class="mobile-label">Industry:</span>
          <mat-chip-list *ngIf="element.industries" aria-label="Industries">
            <mat-chip *ngFor="let industry of element.industries">
              {{industry.name}}
            </mat-chip>
          </mat-chip-list>
        </td>
      </ng-container>

      <!-- Last Login Date Column -->
      <ng-container matColumnDef="lastLoginDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Login Date </th>
        <td mat-cell *matCellDef="let element">
          <span class="mobile-label">Last Login Date:</span>
          {{element.lastLoginDate|date:'mediumDate'}}
        </td>
      </ng-container>

      <!-- Comments Column -->
      <ng-container matColumnDef="comments">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Comments </th>
        <td mat-cell *matCellDef="let element">
          <span class="mobile-label">Comments:</span>
          {{element.comments}}
        </td>
      </ng-container>

      <!-- CRM Link Column -->
      <ng-container matColumnDef="crmLink">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> CRM Link </th>
        <td mat-cell *matCellDef="let element" (click)="$event.stopPropagation()">
          <span class="mobile-label">CRM Link:</span>
          <a *ngIf="element.crmLink" [href]="element.crmLink" target="_blank">CRM</a>
        </td>
      </ng-container>

      <!-- Lockout Column -->
      <ng-container matColumnDef="lockedOut">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Locked? </th>
        <td mat-cell *matCellDef="let element">
          <span class="mobile-label">Locked?:</span>
          {{element.lockedOut}}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row class="user-row" *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <mat-spinner *ngIf="isLoading"></mat-spinner>

  </mat-card-content>

  <mat-card-actions>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
  </mat-card-actions>
</mat-card>