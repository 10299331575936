import { Overlay } from '@angular/cdk/overlay';
import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UsersService } from '~core/data/users.service';
import { Industry } from '~core/models/industry.model';
import { User } from '~core/models/user.model';
import { UserDetailsComponent } from './user-details/user-details.component';
import { UserEditDialogComponent } from './user-edit/user-edit-dialog.component';

@Component({
  selector: 'idl-users-list',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements AfterViewInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  displayedColumns: string[] = [
    'email',
    'firstName',
    'organisationName',
    'industries',
    'lastLoginDate',
    'comments',
    'crmLink',
    'lockedOut'
  ];
  filterColumns: string[] = this.displayedColumns.concat([
    'middleName',
    'lastName'
  ]);

  users: User[];
  filteredUsers$ = new Subject<User[]>();
  dataSource = new MatTableDataSource<User>();

  isLoading = true;

  pipe: DatePipe;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  filterLockedOut = true;

  constructor(
    private _dialog: MatDialog,
    private usersService: UsersService,
    private overlay: Overlay
  ) {
    this.pipe = new DatePipe('en-US');
    this.dataSource = new MatTableDataSource();

    this.dataSource.filterPredicate = (data: User, filters: string) => {
      const matchFilter = [];
      // will split by comma and upto 1 space
      const filterArray = filters.split(/,\s?/);

      // const columns = [data.name, data.race, data.color];
      // Or if you don't want to specify specifics columns =>
      // const columns = (<any>Object).values(data);

      // Main loop
      filterArray.forEach(filter => {
        const customFilter = [];

        // Get only viewed columns from data
        // Assumes view isn't displaying more trhen 1 data field
        this.filterColumns.forEach(columnName => {
          const column = data[columnName];
          if (column) {
            if (column instanceof Date) {
              const pipedDate = this.pipe.transform(column, 'mediumDate');
              // And the piped date contains the value
              customFilter.push(pipedDate.toLowerCase().includes(filter));
            } else if (column instanceof Array) {
              customFilter.push(
                column.filter((industry: Industry) =>
                  industry.name.toLowerCase().includes(filter)
                ).length > 0
              );
            } else {
              customFilter.push(
                column
                  .toString()
                  .toLowerCase()
                  .includes(filter)
              );
            }
          }
        });

        matchFilter.push(customFilter.some(Boolean)); // OR
      });

      return matchFilter.every(Boolean); // AND
    };

    this.filteredUsers$.pipe(takeUntil(this.unsubscribe$)).subscribe(users => {
      this.dataSource.data = users;
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    /* now it's okay to set large data source... */
    this.usersService
      .list()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data: User[]) => {
        this.isLoading = false;
        this.users = data.sort((a, b) =>
          a.email > b.email ? 1 : b.email > a.email ? -1 : 0
        );
        this.filteredUsers$.next(this.globalFilter());
      });
  }

  setLockedOutFilter(event) {
    this.filterLockedOut = event.checked;

    this.filteredUsers$.next(this.globalFilter());
  }

  globalFilter(): User[] {
    if (this.filterLockedOut) {
      const users = this.users.filter(user => !user.lockedOut);

      return users;
    } else {
      return this.users;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  applyFilter(event: KeyboardEvent) {
    this.dataSource.filter = (event.target as HTMLInputElement).value
      .trim()
      .toLowerCase();
  }

  onEditClick(user): void {
    const dlg = this._dialog.open(UserEditDialogComponent, {
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      minWidth: '400px',
      minHeight: '80vh',
      data: user
    });

    dlg
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(userRes => {
        if (userRes) {
          const index = this.dataSource.data.findIndex(
            i => i.userId === userRes.userId
          );
          this.dataSource.data.splice(index, 1, userRes);
          this.dataSource.data = this.dataSource.data;
        }
      });
  }

  onDetailsClick(user): void {
    this._dialog.open(UserDetailsComponent, {
      minWidth: '400px',
      data: user
    });
  }

  addUser() {
    const user = new User();
    user.userId = 0;

    const dlg = this._dialog.open(UserEditDialogComponent, {
      minWidth: '400px',
      data: user
    });

    dlg.afterClosed().subscribe(userres => {
      if (userres) {
        this.dataSource.data.push(userres);
        this.dataSource.data = this.dataSource.data;
      }
    });
  }
}
