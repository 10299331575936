import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Version } from '../../../../core/models/version.model';

export function convertToBoolProperty(val: any): boolean {
  if (typeof val === 'string') {
    val = val.toLowerCase().trim();

    return val === 'true' || val === '';
  }

  return !!val;
}

@Component({
  selector: 'idl-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent {
  /**
   * Specifies version to sow details for
   * @type Version
   */
  @Input() version: Version;

  /**
   * Whether to show a app name or not
   */
  @Input()
  get showName(): boolean {
    return this._showName;
  }
  set showName(val: boolean) {
    this._showName = convertToBoolProperty(val);
  }
  private _showName = true;

  /**
   * Whether to show a app version or not
   * @type boolean
   */
  @Input()
  get showVersion(): boolean {
    return this._showVersion;
  }
  set showVersion(val: boolean) {
    this._showVersion = convertToBoolProperty(val);
  }
  private _showVersion = true;

  /**
   * Whether to show a app description or not
   */
  @Input()
  get showDescription(): boolean {
    return this._showDescription;
  }
  set showDescription(val: boolean) {
    this._showDescription = convertToBoolProperty(val);
  }
  private _showDescription = true;

  /**
   * Whether to show download button or not
   */
  @Input()
  get showDownload(): boolean {
    return this._showDownload;
  }
  set showDownload(val: boolean) {
    this._showDownload = convertToBoolProperty(val);
  }
  private _showDownload = true;

  /**
   * Whether to show edit button or not
   */
  @Input()
  get showEdit(): boolean {
    return this._showEdit;
  }
  set showEdit(val: boolean) {
    this._showEdit = convertToBoolProperty(val);
  }
  private _showEdit = true;

  /**
   * Whether download already in progress
   */
  @Input()
  get downloadLocked(): boolean {
    return this._downloadLocked;
  }
  set downloadLocked(val: boolean) {
    this._downloadLocked = convertToBoolProperty(val);
  }
  private _downloadLocked = false;

  @Output() download: EventEmitter<any> = new EventEmitter();
  @Output() edit: EventEmitter<any> = new EventEmitter();

  constructor() {}

  downloadVersion() {
    this.download.emit(this.version);
  }

  editVersion() {
    this.edit.emit(this.version);
  }
}
