<mat-table [dataSource]="dataSource">
  <!-- Definition Name Column -->
  <ng-container matColumnDef="definitionName">
    <mat-header-cell *matHeaderCellDef> Definition </mat-header-cell>
    <mat-cell *matCellDef="let row">
      <div style='display: flex; flex-direction: column;'>
        <div class="row"><b>{{row.currentData.definitionName}}</b></div>
        <div class="row"><b>Type:</b> {{row.currentData.definitionType}}</div>
        <div class="row"><b>Current Value:</b>
          <div *ngIf="row.currentData.definitionType == 'DateTime'; else normalBlock">
            {{row.currentData.value|date:mediumDate}}
          </div>
          <ng-template #normalBlock>{{row.currentData.value}}</ng-template>
        </div>
      </div>
    </mat-cell>
  </ng-container>

  <!-- New Value Column -->
  <ng-container matColumnDef="newval">
    <mat-header-cell *matHeaderCellDef> Override Value </mat-header-cell>
    <mat-cell *matCellDef="let row">
      <mat-form-field floatLabel="{{ row.editing ? 'always' : 'never'}}">
        <div [ngSwitch]="row.currentData.definitionType">

          <div *ngSwitchCase="'DateTime'">
            <input name="{{row.id}}" [(ngModel)]="row.currentData.newval" [readonly]="!row.editing" matInput
              [matDatepicker]="tablepicker">
            <mat-datepicker-toggle matSuffix [for]="tablepicker" [hidden]="!row.editing"></mat-datepicker-toggle>
            <mat-datepicker #tablepicker disabled="false"></mat-datepicker>
          </div>
          <div *ngSwitchCase="'Text'">
            <input name="{{row.id}}" [(ngModel)]="row.currentData.newval" [readonly]="!row.editing" matInput>
          </div>
          <div *ngSwitchCase="'Value'">
            <input type="number" name="{{row.id}}" [(ngModel)]="row.currentData.newval" [readonly]="!row.editing"
              matInput>
          </div>
        </div>
      </mat-form-field>
    </mat-cell>
  </ng-container>

  <!-- Actions Column -->
  <ng-container matColumnDef="actionsColumn">
    <mat-header-cell *matHeaderCellDef>

    </mat-header-cell>
    <mat-cell *matCellDef="let row">
      <button *ngIf="!row.editing" mat-icon-button color="primary" focusable="false" (click)="row.startEdit()"
        matTooltip="Edit value.">
        <mat-icon>create</mat-icon>
      </button>
      <button *ngIf="row.editing" mat-icon-button color="primary" focusable="false" (click)="createOrEditConfirm(row)"
        matTooltip="Save edit.">
        <mat-icon>check</mat-icon>
      </button>
      <button *ngIf="row.editing" mat-icon-button color="primary" focusable="false" (click)="row.cancelOrDelete()"
        matTooltip="Cancel edit.">
        <mat-icon>clear</mat-icon>
      </button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

</mat-table>