import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { selectUserId } from '../../../core/auth/store/auth.selectors';
import { AppState } from '../../../core/core.module';
import { User } from '../../../core/models/user.model';

@Component({
  selector: 'idl-user-edit-dialog',
  template: `
    <h2 mat-dialog-title *ngIf="addUser; else elseBlock">Add User</h2>
    <ng-template #elseBlock><h2 mat-dialog-title>User Profile</h2></ng-template>
    <mat-dialog-content class="user-edit-dialog">
      <mat-tab-group #appTabs mat-stretch-tabs class="user-edit-tabgroup">
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">apps</mat-icon>
            User Details
          </ng-template>
          <ng-template matTabContent class="user-edit-tab">
            <div class="user-form">
              <idl-user-edit
                #child
                [addUser]="addUser"
                [userId]="user.userId"
                [editSelf]="editSelf"
                [saveEvent]="saveSubject"
                (saved)="saved($event)"
              ></idl-user-edit>
            </div>
            <div class="save-area">
              <button
                class="save-button"
                mat-raised-button
                color="accent"
                (click)="submit()"
                [disabled]="child.userForm.invalid"
              >
                <div *ngIf="addUser; else buttonElseBlock">Create</div>
                <ng-template #buttonElseBlock>Save</ng-template>
              </button>
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab *ngIf="!addUser">
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">apps</mat-icon>
            User App Settings
          </ng-template>
          <ng-template matTabContent>
            <idl-user-app-settings
              [userId]="user.userId"
            ></idl-user-app-settings>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
      <button mat-button mat-dialog-close>Close</button>
    </mat-dialog-actions>
  `,
  styles: [
    '.user-edit-tabgroup { max-height: inherit; } ',
    '.user-form { flex-grow: 1; overflow: auto; } ',
    '.mat-tab-body-content { display: flex; flex-flow: column; }',
    '.save-area { padding: 5px; }',
    '.save-button { float: right; }'
  ],
  encapsulation: ViewEncapsulation.None
})
export class UserEditDialogComponent {
  private unsubscribe$ = new Subject<void>();

  user: User;
  addUser = false;
  editSelf = false;

  public saveSubject: Subject<void> = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private _dialogRef: MatDialogRef<UserEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: User
  ) {
    if (dialogData) {
      this.user = dialogData;
      if (this.user.userId === 0) {
        this.addUser = true;
      } else {
        this.store
          .pipe(select(selectUserId))
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((id: string) => {
            if (+id === this.user.userId) this.editSelf = true;
          });
      }
    } else {
      this.user = new User();
    }
  }

  submit() {
    this.saveSubject.next();
  }

  saved(user) {
    this._dialogRef.close(user);
  }
}
