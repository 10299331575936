<mat-card>
  <mat-card-title>
    <div *ngIf="myLicenses; else mainTitle">
      Application Downloads
    </div>
    <ng-template #mainTitle>
      Licenses
    </ng-template>
  </mat-card-title>

  <mat-card-subtitle fxLayout="column" fxLayoutAlign="space-around start" fxLayoutGap="20px">
    <div *ngIf="application">
      <button mat-raised-button color="accent" (click)="addLicense()">Add New License</button>
    </div>
    <mat-form-field class="filter">
      <input matInput (keyup)="applyFilter($event)" placeholder="Filter">
      <mat-hint>Comma seperate filter terms. (Eg. application, username)</mat-hint>
    </mat-form-field>
  </mat-card-subtitle>

  <mat-card-content>

    <mat-card *ngIf="!organisationId && !userId" class="quick-filter-card">
      <div class="quick-filter-title">Quick Filters</div>
      <mat-radio-group aria-label="Quick view options" [(ngModel)]="selectedQuickFilter" (change)="radioChange($event)"
        [disabled]="advanceFilterApplied">
        <mat-radio-button class="quick-filter-radio-button" *ngFor="let quickFilter of quickFilters"
          [value]="quickFilter">
          {{quickFilter.title}}
        </mat-radio-button>
      </mat-radio-group>

      <mat-expansion-panel #matExpansionPanel>
        <mat-expansion-panel-header (click)="expandPanel(matExpansionPanel, $event)">
          <mat-panel-title>
            Advanced Filtering
          </mat-panel-title>
          <mat-panel-description>
            Set all filter parameters
          </mat-panel-description>
        </mat-expansion-panel-header>

        <form [formGroup]="filterForm" class="advanced-filter-form">

          <mat-form-field class="advanced-filter-control">
            <mat-label>Filter</mat-label>
            <mat-select formControlName="filter">
              <mat-option *ngFor="let opt of filterOptions" [value]="opt">{{opt.key}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="advanced-filter-control">
            <mat-label>Only Type</mat-label>
            <mat-select formControlName="onlytype">
              <mat-option *ngFor="let opt of typeOptions" [value]="opt">{{opt.key}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="advanced-filter-control">
            <mat-label>Exclude Type</mat-label>
            <mat-select formControlName="excludetype">
              <mat-option *ngFor="let opt of typeOptions" [value]="opt">{{opt.key}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="advanced-filter-control">
            <mat-label>Days</mat-label>
            <input matInput formControlName="days" type="number" placeholder="0">
          </mat-form-field>

          <mat-checkbox formControlName="showRevoked" class="advanced-filter-control">Show Revoked</mat-checkbox>

          <button mat-raised-button color="accent" (click)="applyAdvanceFilter()"
            class="advanced-filter-control">Apply</button>
          <button *ngIf="advanceFilterApplied" mat-stroked-button status="danger" color="warn"
            (click)="clearAdvanceFilter()" class="advanced-filter-control">Clear</button>

        </form>
      </mat-expansion-panel>
    </mat-card>

    <div class="row" *ngIf="!application && !organisationId && !userId">
      <div class="col">
        <button mat-raised-button color="accent" (click)="exportCsv()">Export Licenses</button>
      </div>
    </div>

    <mat-table *ngIf="!isLoading" [dataSource]="dataSource" matSort>
      <!-- Appication Column -->
      <ng-container matColumnDef="applicationName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Application</mat-header-cell>
        <mat-cell fxLayout="column" fxLayoutAlign="space-around start" *matCellDef="let license">
          <span class="mobile-label">Application:</span>
          <idl-license-appname *ngIf="myLicenses; else appNameElse" [rowData]="license"></idl-license-appname>
          <ng-template #appNameElse>{{license.applicationName}}</ng-template>

          <div class="row-actions">
            <button (click)="onDetailsClick(license)">View Details</button> |
            <button *nbIsGranted="['view', 'license_history']" (click)="onHistoryClick(license)">View History</button>
          </div>
        </mat-cell>
      </ng-container>

      <!-- Metadata Column -->
      <ng-container matColumnDef="metadataSetName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Customisation</mat-header-cell>
        <mat-cell fxLayout="column" fxLayoutAlign="space-around start" *matCellDef="let license">
          <span class="mobile-label">Customisation:</span>
          {{license.metadataSetName}}
          <div *ngIf="application" class="row-actions">
            <button (click)="onDetailsClick(license)">View Details</button> |
            <button *nbIsGranted="['view', 'license_history']" (click)="onHistoryClick(license)">View History</button>
          </div>
        </mat-cell>
      </ng-container>

      <!-- Metadata Title Column -->
      <ng-container matColumnDef="metadataSetTitle">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Edition:</mat-header-cell>
        <mat-cell *matCellDef="let license">
          <span class="mobile-label">Edition:</span>
          {{license.metadataSetTitle}}
        </mat-cell>
      </ng-container>

      <!-- License Holder Column -->
      <ng-container matColumnDef="licenseHolderName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>License Holder</mat-header-cell>
        <mat-cell *matCellDef="let license">
          <span class="mobile-label">License Holder:</span>
          {{license.licenseHolderName}}
        </mat-cell>
      </ng-container>

      <!-- Organisation Column -->
      <ng-container matColumnDef="organisationName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Organisation </mat-header-cell>
        <mat-cell *matCellDef="let license">
          <span class="mobile-label">Organisation:</span>
          {{license.organisationName}}
        </mat-cell>
      </ng-container>

      <!-- Industry Column -->
      <ng-container matColumnDef="industries">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Industry </mat-header-cell>
        <mat-cell *matCellDef="let license">
          <span class="mobile-label">Industry:</span>
          <mat-chip-list *ngIf="license.industries" aria-label="Industries">
            <mat-chip *ngFor="let industry of license.industries">
              {{industry.name}}
            </mat-chip>
          </mat-chip-list>
        </mat-cell>
      </ng-container>

      <!-- Type Column -->
      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
        <mat-cell *matCellDef="let license">
          <span class="mobile-label">Type:</span>
          {{license.type}}
        </mat-cell>
      </ng-container>

      <!-- Expiry Date Column -->
      <ng-container matColumnDef="expirydate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Expiry Date</mat-header-cell>
        <mat-cell *matCellDef="let license">
          <div *ngIf="license.type != 'Perpetual'">
            <span class="mobile-label">Expiry Date:</span>
            {{license.expirydate|date:'mediumDate'}}
          </div>
        </mat-cell>
      </ng-container>

      <!-- Creation Date Column -->
      <ng-container matColumnDef="creationdate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Creation Date</mat-header-cell>
        <mat-cell *matCellDef="let license">
          <span class="mobile-label">Creation Date:</span>
          {{license.creationdate|date:'mediumDate'}}
        </mat-cell>
      </ng-container>

      <!-- Created Column -->
      <ng-container matColumnDef="createdByUserName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Created By</mat-header-cell>
        <mat-cell *matCellDef="let license">
          <div>
            <span class="mobile-label">Created By:</span>
            <div class="name">{{license.createdByUserName}}</div>
            <div class="creationdate">Date: {{license.creationdate|date:'mediumDate'}}</div>
          </div>
        </mat-cell>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef>Download</mat-header-cell>
        <mat-cell *matCellDef="let license">
          <span class="mobile-label">Download:</span>
          <idl-license-actions [rowData]="license" (download)="downloadLatest(license)"
            (getlicense)="downloadLicense(license)" (versions)="viewVersions(license)"></idl-license-actions>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <ng-container *ngIf="myLicenses; else rowElseBlock">
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </ng-container>
      <ng-template #rowElseBlock>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </ng-template>

    </mat-table>

    <mat-spinner *ngIf="isLoading"></mat-spinner>

  </mat-card-content>
  <mat-card-actions>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
  </mat-card-actions>
</mat-card>