export const LicenseExportHeaders = [
  'Organisation',
  'Application',
  'MetadataSet',
  'AppVariant',
  'LicenseType',
  'ExpiryDate',
  'LicenseHolder',
  'Email',
  'CreatedBy',
  'CreationDate',
  'Comments',
  'Industries'
];

export class LicenseExport {
  constructor(
    public organisationName: string,
    public applicationName: string,
    public metadataSetName: string,
    public metadataSetTitle: string,
    public type: string,
    public expirydate: Date,
    public licenseHolderName: string,
    public licenseHolderEmail: string,
    public createdByUserName: string,
    public creationdate: Date,
    public comments: string,
    public industries: string
  ) {}

  static adapt(item: any): LicenseExport {
    return new LicenseExport(
      item.organisationName,
      item.applicationName,
      item.metadataSetName,
      item.metadataSetTitle,
      item.type,
      item.expirydate,
      item.licenseHolderName,
      item.licenseHolderEmail,
      item.createdByUserName,
      item.creationdate,
      item.comments,
      item.industries.map(i => i.name).toString()
    );
  }
}
