import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FilterSelectClearDirective } from './filter-select-clear.directive';
import { FilterSelectComponent } from './filter-select.component';

@NgModule({
  declarations: [FilterSelectComponent, FilterSelectClearDirective],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule
  ],
  exports: [FilterSelectComponent, FilterSelectClearDirective]
})
export class FilterSelectModule {}
