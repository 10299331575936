<h2 mat-dialog-title>Enter name of new Version Group</h2>
<mat-dialog-content>
  <form #groupForm="ngForm">
    <div class="row">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Group Name</mat-label>
        <input [(ngModel)]="newGroup.name" matInput placeholder="Group Name" name="groupName" required>
      </mat-form-field>
    </div>
    <div class="checkbox-wrapper">
      <mat-checkbox name="excludeVersion" [(ngModel)]="newGroup.excludeFromVersionFilter">Exclude from version filter.
      </mat-checkbox>
    </div>
    <div class="row">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Group Start Date</mat-label>
        <input matInput [matDatepicker]="picker" placeholder="Start Date" name="startDate"
          [(ngModel)]="newGroup.startDate">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Release Processing (Engine Name)</mat-label>
        <input [(ngModel)]="newGroup.releaseProcessing" matInput placeholder="Engine Name" name="releaseProcessing">
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button status="danger" mat-dialog-close>Cancel</button>
  <button mat-raised-button color="primary" status="success" [mat-dialog-close]="newGroup"
    [disabled]="!groupForm.form.valid" cdkFocusInitial>Submit</button>
</mat-dialog-actions>