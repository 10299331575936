export class AITMenuItem {
  link: string;
  label: string;
  hidden?: boolean;
  data?: any;
}

export const MENU_ITEMS: AITMenuItem[] = [
  {
    link: '/dashboard',
    label: 'idl.menu.dashboard',
    data: { permission: 'view', resource: 'user' }
  },
  {
    link: '/licenses',
    label: 'idl.menu.licenses',
    data: { permission: 'view', resource: 'license_list' }
  },
  {
    link: '/applications',
    label: 'idl.menu.applications',
    data: { permission: 'view', resource: 'applications_list' }
  },
  {
    link: '/users',
    label: 'idl.menu.users',
    data: { permission: 'view', resource: 'users' }
  },
  {
    link: '/organisations',
    label: 'idl.menu.organisations',
    data: { permission: 'view', resource: 'organisations' }
  },
  {
    link: '/industries',
    label: 'idl.menu.industries',
    data: { permission: 'view', resource: 'industries' }
  },
  {
    link: '/logs',
    label: 'idl.menu.logs',
    data: { permission: 'view', resource: 'logs' }
  },
  {
    link: '/profile',
    label: 'idl.menu.profile',
    data: { permission: 'view', resource: 'user' }
  }
];
