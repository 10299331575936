import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LogsService } from '~core/data/logs.service';
import { Log } from '~core/models/logs.model';
import { User } from '~core/models/user.model';

@Component({
  selector: 'idl-user-logins',
  templateUrl: './user-logins.component.html',
  styleUrls: ['./user-logins.component.scss']
})
export class UserLoginsComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  displayedColumns: string[] = ['logDate', 'ipAddress'];

  isLoading = true;

  logs: Log[];
  dataSource = new MatTableDataSource<Log>();

  pipe: DatePipe;

  @Input() user: User;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private logsService: LogsService) {
    this.pipe = new DatePipe('en-US');
  }

  ngOnInit(): void {
    this.logsService
      .userlogins(this.user.userId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        logs => {
          this.isLoading = false;
          this.logs = logs;
          this.dataSource = new MatTableDataSource(this.logs);

          this.dataSource.filterPredicate = (data: Log, filters: string) => {
            const matchFilter = [];
            // will split by comma and upto 1 space
            const filterArray = filters.split(/,\s?/);

            // const columns = [data.name, data.race, data.color];
            // Or if you don't want to specify specifics columns =>
            // const columns = (<any>Object).values(data);

            // Main loop
            filterArray.forEach(filter => {
              const customFilter = [];

              // Get only viewed columns from data
              // Assumes view isn't displaying more trhen 1 data field
              this.displayedColumns.forEach(columnName => {
                const column = data[columnName];
                if (column) {
                  if (column instanceof Date) {
                    const pipedDate = this.pipe.transform(column, 'mediumDate');
                    // And the piped date contains the value
                    customFilter.push(pipedDate.toLowerCase().includes(filter));
                  } else {
                    customFilter.push(
                      column
                        .toString()
                        .toLowerCase()
                        .includes(filter)
                    );
                  }
                }
              });

              matchFilter.push(customFilter.some(Boolean)); // OR
            });
            return matchFilter.every(Boolean); // AND
          };

          setTimeout(() => {
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
          });
        },
        err => {
          this.isLoading = false;
        }
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  applyFilter(event: KeyboardEvent) {
    this.dataSource.filter = (event.target as HTMLInputElement).value
      .trim()
      .toLowerCase();
  }
}
