import { Action } from '@ngrx/store';

export enum AuthActionTypes {
  Login = '[Login Page] Login',
  LoginComplete = '[Login Page] Login Complete',
  LoginSuccess = '[Auth API] Login Success',
  LoginFailure = '[Auth API] Login Failure',
  CheckLogin = '[Auth] Check Login',
  Logout = '[Auth] Confirm Logout',
  LogoutCancelled = '[Auth] Logout Cancelled',
  LogoutConfirmed = '[Auth] Logout Confirmed',
  RefreshLogin = '[Auth] Refresh Login',
  RefreshComplete = '[Auth] Refresh Complete',
  UpdateUser = '[Auth] Update User'
}

export class Login implements Action {
  readonly type = AuthActionTypes.Login;
  constructor(public targetroute: string) {}
}

export class LoginComplete implements Action {
  readonly type = AuthActionTypes.LoginComplete;
  constructor(
    public user: any,
    public loggedin: boolean,
    public id: string,
    public roles: string,
    public targetroute: string
  ) {}
}

export class LoginSuccess implements Action {
  readonly type = AuthActionTypes.LoginSuccess;

  constructor(public payload: any) {}
}

export class LoginFailure implements Action {
  readonly type = AuthActionTypes.LoginFailure;

  constructor(public payload: any) {}
}

export class CheckLogin implements Action {
  readonly type = AuthActionTypes.CheckLogin;
}

export class Logout implements Action {
  readonly type = AuthActionTypes.Logout;
}

export class LogoutConfirmed implements Action {
  readonly type = AuthActionTypes.LogoutConfirmed;
}

export class LogoutCancelled implements Action {
  readonly type = AuthActionTypes.LogoutCancelled;
}

export class RefreshLogin implements Action {
  readonly type = AuthActionTypes.RefreshLogin;
}

export class RefreshComplete implements Action {
  readonly type = AuthActionTypes.RefreshComplete;
  constructor(
    public user: any,
    public token: string,
    public loggedin: boolean,
    public id: string,
    public roles: string[]
  ) {}
}

export class UpdateUser implements Action {
  readonly type = AuthActionTypes.UpdateUser;
  constructor(public user: any) {}
}

export type AuthActions =
  | Login
  | LoginComplete
  | LoginSuccess
  | LoginFailure
  | CheckLogin
  | RefreshLogin
  | RefreshComplete
  | Logout
  | LogoutCancelled
  | LogoutConfirmed
  | UpdateUser;
