import { ResponseInterface } from './types';

export class VersionUpload {
  constructor(
    public versionGroupId?: number,
    public version?: string,
    public comments?: string,
    public importantUpdate?: boolean,
    public noNotifications?: boolean,
    public overrideDate?: Date
  ) {}
}

export class Version {
  constructor();
  constructor(
    fileId: number,
    applicationId: number,
    uploadedByUserId: number,
    name: string,
    uploadDate: Date,
    storageLocation: string,
    version: string,
    comments: string,
    hidden: boolean,
    overrideDate?: Date
  );
  constructor(
    public fileId?: number,
    public applicationId?: number,
    public uploadedByUserId?: number,
    public name?: string,
    public uploadDate?: Date,
    public storageLocation?: string,
    public version?: string,
    public comments?: string,
    public hidden?: boolean,
    public overrideDate?: Date,
    public classID?: number
  ) {}

  static adapt(item: any): Version {
    return new Version(
      item.fileId,
      item.applicationId,
      item.uploadedByUserId,
      item.name,
      new Date(item.uploadDate),
      item.storageLocation,
      item.version,
      item.comments,
      item.hidden,
      item.overrideDate
    );
  }
}

export class VersionsData {
  constructor(public versions: Version[]) {}
}

export class VersionsResponse implements ResponseInterface {
  status = '';
  data: VersionsData;
  constructor() {}
}

export class VersionResponse implements ResponseInterface {
  status = '';
  data: Version;
  constructor() {}
}
