<h2 mat-dialog-title>{{user.firstName}} {{user.middleName}} {{user.lastName}} Details</h2>

<mat-dialog-content>

  <mat-tab-group #appTabs animationDuration="500ms" mat-stretch-tabs>

    <mat-tab>
      <ng-template mat-tab-label>
        Licenses
      </ng-template>
      <ng-template matTabContent>
        <idl-licenses [userId]="user.userId" [userDetails]="true"></idl-licenses>
      </ng-template>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        Logins
      </ng-template>
      <ng-template matTabContent>
        <idl-user-logins [user]="user"></idl-user-logins>
      </ng-template>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        Downloads
      </ng-template>
      <ng-template matTabContent>
        <idl-user-downloads [user]="user"></idl-user-downloads>
      </ng-template>
    </mat-tab>

  </mat-tab-group>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>