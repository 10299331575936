import { ResponseInterface } from './types';

export class SetPassword {
  constructor(
    public email: string,
    public token: string,
    public password: string,
    public termsAccepted: boolean
  ) {}

  static adapt(item: any): SetPassword {
    return new SetPassword(
      item.email,
      item.token,
      item.password,
      item.termsAccepted
    );
  }
}

export class SetPasswordData {
  constructor(public setpassword: SetPassword[]) {}
}

export class SetPasswordResponse implements ResponseInterface {
  status = '';
  data: SetPasswordData;
  constructor() {}
}
