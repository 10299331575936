import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { Observable, of, Subject } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { selectUserId } from '~core/auth/store/auth.selectors';
import { AppState } from '~core/core.module';
import { ApplicationLicenseService } from '~core/data/application-license.service';
import { ApplicationsService } from '~core/data/applications.service';
import { FilesService } from '~core/data/files.service';
import { LicenseService } from '~core/data/license.service';
import { Application } from '~core/models/application.model';
import { Industry } from '~core/models/industry.model';
import { License } from '~core/models/license.model';
import { ProgressDialogComponent } from '~core/utils/progress-dialog/progress-dialog.component';
import { ApplicationService } from '../applications/application.service';
import { VersionDialogComponent } from '../applications/versions/version-dialog.component';
import { GenerateLicenseDialogComponent } from './generate-license/generate-license-dialog.component';
import { LicenseDetailsDialogComponent } from './license-details-dialog.component';
import { LicenseExport, LicenseExportHeaders } from './license-export';
import { LicenseHistoryDialogComponent } from './license-history-dialog.component';

export class LicenseQuickFilter {
  constructor(
    public title: string,
    public filterString: string,
    public onlyType: string,
    public excludeType: string,
    public days: number
  ) {}
}

enum QuickFilter {
  AllExcludingDemo = 0,
  Active,
  RecentlyExpired,
  ExpiresSoon,
  All,
  Demos,
  Valid
}

@Component({
  selector: 'idl-licenses',
  templateUrl: './licenses.component.html',
  styleUrls: ['./licenses.component.scss']
})
export class LicensesComponent implements OnInit, AfterViewInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  pipe: DatePipe;

  private progress$ = new Subject();

  @Input() myLicenses = false;
  @Input() userId = 0;
  licenseholderColumns: string[] = [
    'applicationName',
    'metadataSetTitle',
    'type',
    'expirydate',
    'action'
  ];
  managerColumns: string[] = [
    'applicationName',
    'metadataSetName',
    'licenseHolderName',
    'organisationName',
    'industries',
    'type',
    'expirydate',
    'createdByUserName'
  ];
  userDetailsColumns: string[] = [
    'applicationName',
    'metadataSetTitle',
    'type',
    'expirydate',
    'createdByUserName'
  ];
  appEditColumns: string[] = [
    'metadataSetName',
    'licenseHolderName',
    'organisationName',
    'industries',
    'type',
    'expirydate',
    'createdByUserName'
  ];
  displayedColumns: string[];

  dataSource = new MatTableDataSource<License>();
  licenses: License[];
  filteredLicenses$ = new Subject<License[]>();

  quickFilters: LicenseQuickFilter[] = [
    new LicenseQuickFilter('All exluding Demos', 'all', '', 'Demo', 0),
    new LicenseQuickFilter('Active', 'active', '', '', 0),
    new LicenseQuickFilter('Recently Expired', 'expired', '', '', 30),
    new LicenseQuickFilter('Expires Soon', 'nearexpire', '', '', 60),
    new LicenseQuickFilter('All', 'all', '', '', 0),
    new LicenseQuickFilter('Demos', 'all', 'Demo', '', 0),
    new LicenseQuickFilter('Valid', 'valid', '', '', 0)
  ];

  selectedQuickFilter = this.quickFilters[QuickFilter.AllExcludingDemo];

  filterOptions = [
    { key: 'All', filter: 'all', onlytype: '', excludetype: '' },
    { key: 'Active', filter: 'active', onlytype: '', excludetype: '' },
    { key: 'Expired', filter: 'expired', onlytype: '', excludetype: '' },
    {
      key: 'Expired and Current',
      filter: 'expiredcurrent',
      onlytype: '',
      excludetype: ''
    },
    { key: 'Expires Soon', filter: 'nearexpire', onlytype: '', excludetype: '' }
  ];

  typeOptions = [
    { key: '' },
    { key: 'Demo' },
    { key: 'Subscription' },
    { key: 'Perpetual' }
  ];

  filterForm: FormGroup;

  advanceFilterApplied = false;

  @Input() application: Application;
  @Input() userDetails = false;
  @Input() organisationId: number;

  // @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  // _rowClickHandler = this.onRowClick.bind(this);

  renderedData: any;

  isLoading = true;
  isDownloading = false;

  constructor(
    private route: ActivatedRoute,
    private licenseService: LicenseService,
    private filesService: FilesService,
    private appLicenseService: ApplicationLicenseService,
    private _applicationService: ApplicationService,
    private applicationsService: ApplicationsService,
    private dialog: MatDialog,
    private store: Store<AppState>
  ) {
    this.pipe = new DatePipe('en-US');
    this.dataSource = new MatTableDataSource();

    this.dataSource
      .connect()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        d =>
          (this.renderedData = this.dataSource.filteredData.map(
            LicenseExport.adapt
          ))
      );

    this.filterForm = new FormGroup({
      filter: new FormControl(null),
      onlytype: new FormControl(null),
      excludetype: new FormControl(null),
      days: new FormControl(0),
      showRevoked: new FormControl(false)
    });

    this.filterForm.controls['filter'].setValue(this.filterOptions[0], {
      onlySelf: true
    });

    this.filteredLicenses$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(licenses => {
        this.dataSource.data = licenses.sort((a, b) =>
          a.organisationName > b.organisationName
            ? 1
            : b.organisationName > a.organisationName
            ? -1
            : 0
        );
        this.isLoading = false;
      });
  }

  ngOnInit() {
    this.dataSource.filterPredicate = (data: License, filters: string) => {
      const matchFilter = [];
      // will split by comma and upto 1 space
      const filterArray = filters.split(/,\s?/);

      // const columns = [data.name, data.race, data.color];
      // Or if you don't want to specify specifics columns =>
      // const columns = (<any>Object).values(data);

      // Main loop
      filterArray.forEach(filter => {
        const customFilter = [];

        // Get only viewed columns from data
        // Assumes view isn't displaying more trhen 1 data field
        this.displayedColumns.forEach(columnName => {
          const column = data[columnName];
          if (column)
            if (column instanceof Date) {
              const pipedDate = this.pipe.transform(column, 'mediumDate');
              // And the piped date contains the value
              customFilter.push(pipedDate.toLowerCase().includes(filter));
            } else if (column instanceof Array) {
              customFilter.push(
                column.filter((industry: Industry) =>
                  industry.name.toLowerCase().includes(filter)
                ).length > 0
              );
            } else {
              customFilter.push(
                column
                  .toString()
                  .toLowerCase()
                  .includes(filter)
              );
            }
        });

        matchFilter.push(customFilter.some(Boolean)); // OR
      });
      return matchFilter.every(Boolean); // AND
    };

    if (!this.route.parent) {
      if (this.userDetails) {
        this.displayedColumns = this.userDetailsColumns;
      } else {
        this.displayedColumns = this.managerColumns;
      }

      return;
    }

    this.route.parent.paramMap
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((params: ParamMap) => {
          if (params && params.get('id')) {
            return this._applicationService.getApp(+params.get('id'));
          } else {
            return of(null);
          }
        })
      )
      .subscribe(app => {
        if (app) this.application = app;

        this.route.data.pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
          if (data.myLicenses) this.myLicenses = data.myLicenses;

          if (this.myLicenses) {
            this.selectedQuickFilter = this.quickFilters[QuickFilter.Valid];
            this.displayedColumns = this.licenseholderColumns;

            this.store
              .pipe(select(selectUserId))
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe((id: string) => {
                this.userId = +id;
              });
          } else {
            if (this.application) {
              this.displayedColumns = this.appEditColumns;
            } else if (this.userDetails) {
              this.displayedColumns = this.userDetailsColumns;
            } else {
              this.displayedColumns = this.managerColumns;
            }
          }
        });
      });
  }

  ngAfterViewInit() {
    // this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    /* now it's okay to set large data source... */
    this.loadLicenseData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  applyFilter(event: KeyboardEvent) {
    this.dataSource.filter = (event.target as HTMLInputElement).value
      .trim()
      .toLowerCase();
  }

  applyAdvanceFilter() {
    this.advanceFilterApplied = true;
    this.isLoading = true;
    this.loadLicenseData();
  }

  clearAdvanceFilter() {
    this.advanceFilterApplied = false;
    this.isLoading = true;
    this.loadLicenseData();
  }

  expandPanel(matExpansionPanel: MatExpansionPanel, event: Event): void {
    if (this.advanceFilterApplied) {
      event.stopPropagation();
      matExpansionPanel.toggle();
    }
  }

  loadLicenseData() {
    let query: Observable<License[]>;
    if (this.application) {
      query = this.licenseService
        .list(this.application.applicationId, this.userId)
        .pipe(takeUntil(this.unsubscribe$), take(1));
    } else if (this.organisationId) {
      query = this.licenseService
        .list(null, this.userId, this.organisationId)
        .pipe(takeUntil(this.unsubscribe$), take(1));
    } else {
      if (this.advanceFilterApplied) {
        query = this.licenseService
          .list(
            null,
            this.userId,
            null,
            this.filterForm.controls['filter'].value.filter,
            this.filterForm.controls['onlytype'].value
              ? this.filterForm.controls['onlytype'].value.key
              : null,
            this.filterForm.controls['excludetype'].value
              ? this.filterForm.controls['excludetype'].value.key
              : null,
            this.filterForm.controls['days'].value,
            !this.filterForm.controls['showRevoked'].value
          )
          .pipe(takeUntil(this.unsubscribe$), take(1));
      } else {
        query = this.licenseService
          .list(
            null,
            this.userId,
            null,
            this.selectedQuickFilter.filterString,
            this.selectedQuickFilter.onlyType,
            this.selectedQuickFilter.excludeType,
            this.selectedQuickFilter.days,
            true
          )
          .pipe(takeUntil(this.unsubscribe$), take(1));
      }
    }
    query.subscribe(
      data => {
        this.filteredLicenses$.next(data);
      },
      err => {
        this.isLoading = false;
      }
    );
  }

  downloadLatest(license: License) {
    this.appLicenseService
      .latestVersion(license.applicationId, license.licenseRecordId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(versions => {
        if (versions.length > 0) {
          const version = versions[versions.length - 1];
          if (version.fileId > 0) {
            const dialogRef = this.dialog.open(ProgressDialogComponent, {
              maxWidth: '650px',
              width: '90%',
              disableClose: true,
              data: {
                message: 'Downloading ' + version.name,
                progress$: this.progress$
              }
            });

            dialogRef.afterOpened().subscribe(() => {
              this.filesService
                .download(version.fileId, license.licenseRecordId)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(event => {
                  if (event.blob) {
                    // It is necessary to create a new blob object with mime-type explicitly set
                    // otherwise only Chrome works like it should
                    const newBlob = new Blob([event.blob], {
                      type: event.type
                    });

                    dialogRef.close();

                    // IE doesn't allow using a blob object directly as link href
                    // instead it is necessary to use msSaveOrOpenBlob
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                      window.navigator.msSaveOrOpenBlob(newBlob, version.name);
                      return;
                    }

                    // For other browsers:
                    // Create a link pointing to the ObjectURL containing the blob.
                    const data = window.URL.createObjectURL(newBlob);

                    const link = document.createElement('a');
                    link.href = data;
                    link.download = version.name;
                    // this is necessary as link.click() does not work on the latest firefox
                    link.dispatchEvent(
                      new MouseEvent('click', {
                        bubbles: true,
                        cancelable: true,
                        view: window
                      })
                    );

                    setTimeout(function() {
                      // For Firefox it is necessary to delay revoking the ObjectURL
                      window.URL.revokeObjectURL(data);
                      link.remove();
                    }, 100);
                  } else {
                    this.progress$.next(event.progress);
                  }
                });
            });
          }
        }
      });
  }

  downloadLicense(viewlicense: License) {
    if (viewlicense) {
      this.licenseService
        .get(viewlicense.licenseRecordId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(license => {
          this.applicationsService
            .get(license.applicationId)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(app => {
              const newBlob = new Blob([license.licenseData], {
                type: 'application/xml'
              });

              // IE doesn't allow using a blob object directly as link href
              // instead it is necessary to use msSaveOrOpenBlob
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob, app.licenseKeyName);
                return;
              }

              // For other browsers:
              // Create a link pointing to the ObjectURL containing the blob.
              const data = window.URL.createObjectURL(newBlob);

              const link = document.createElement('a');
              link.href = data;
              link.download = app.licenseKeyName;
              // this is necessary as link.click() does not work on the latest firefox
              link.dispatchEvent(
                new MouseEvent('click', {
                  bubbles: true,
                  cancelable: true,
                  view: window
                })
              );

              setTimeout(function() {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
                link.remove();
              }, 100);
            });
        });
    }
  }

  viewVersions(license: License) {
    this.applicationsService
      .get(license.applicationId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(app => {
        this.dialog.open(VersionDialogComponent, {
          height: '80%',
          width: '70%',
          data: {
            application: app,
            license: license
          }
        });
      });
  }

  radioChange(event) {
    this.isLoading = true;
    this.loadLicenseData();
  }

  /**
   * Popup generate license component to add a new license
   */
  addLicense() {
    const dlg = this.dialog.open(GenerateLicenseDialogComponent, {
      maxWidth: '800px',
      data: {
        applicationId: this.application.applicationId,
        license: null
      }
    });

    dlg
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(res => {
        if (res) {
          this.isLoading = true;
          this.loadLicenseData();
        }
      });
  }

  onDetailsClick(license): void {
    const dlg = this.dialog.open(LicenseDetailsDialogComponent, {
      height: '80%',
      width: '99%',
      maxWidth: '800px',
      minHeight: '620px',
      data: license
    });

    dlg
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(res => {
        if (res) {
          this.isLoading = true;
          this.loadLicenseData();
        }
      });
  }

  onHistoryClick(license): void {
    const dlg = this.dialog.open(LicenseHistoryDialogComponent, {
      height: '80%',
      width: '99%',
      maxWidth: '800px',
      minHeight: '620px',
      data: license.licenseRecordId
    });

    dlg
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(res => {
        if (res) {
          this.isLoading = true;
          this.loadLicenseData();
        }
      });
  }

  exportCsv() {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      headers: LicenseExportHeaders,
      nullToEmptyString: true
    };
    const acsv = new AngularCsv(this.renderedData, 'LicenseExport', options);
  }
}
