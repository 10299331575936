import { Industry } from './industry.model';
import { ResponseInterface } from './types';

export class Organisation {
  constructor();
  constructor(
    organisationId: number,
    name: string,
    comments: string,
    crmLink: string,
    creationDate: Date,
    createdById: number,
    industries: Industry[]
  );
  constructor(
    public organisationId?: number,
    public name?: string,
    public comments?: string,
    public crmLink?: string,
    public creationDate?: Date,
    public createdById?: number,
    public industries?: Industry[]
  ) {}

  static adapt(item: any): Organisation {
    return new Organisation(
      item.organisationId,
      item.name,
      item.comments,
      item.crmLink,
      new Date(item.creationDate),
      item.createdById,
      item.industries.map(Industry.adapt)
    );
  }
}

export class OrganisationsData {
  constructor(public organisations: Organisation[]) {}
}

export class OrganisationsResponse implements ResponseInterface {
  status = '';
  data: OrganisationsData;
  constructor() {}
}

export class OrganisationResponse implements ResponseInterface {
  status = '';
  data: Organisation;
  constructor() {}
}
