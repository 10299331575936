import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  ChangeLicenseRequest,
  License,
  LicenseResponse,
  LicensesResponse
} from '../models/license.model';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {
  /**
   * Base url of license service
   */
  private baseUrl = 'api/licenses';

  constructor(private http: HttpClient) {}

  /**
   * Lists all user licenses managed by logged in user
   * @param [appid] Application ID to filter licenses by
   * @param [userId] User ID to filter licenses by
   * @param [orgid] Organisation ID to filter licenses by
   * @param [filter] Filter keyword {all|active|expired|expiredcurrent}|nearexpire|demo|perpetual}
   * @param [onlytype] Only return licenses of type
   * @param [excludetype] Exclude all licenses of type
   * @param [days] used by filters {expired|expiredcurrent}|nearexpire}
   * @param [norevoked] Filter revoked licenses
   * @returns array of License object
   */
  list(
    appid?: number,
    userId?: number,
    orgid?: number,
    filter?: string,
    onlytype?: string,
    excludetype?: string,
    days?: number,
    norevoked?: boolean
  ): Observable<License[]> {
    const url = `${this.baseUrl}`;
    let params = new HttpParams();
    if (appid) params = params.append('appid', appid.toString());
    if (userId) params = params.append('userId', userId.toString());
    if (orgid) params = params.append('orgid', orgid.toString());
    if (filter) params = params.append('filter', filter);
    if (onlytype) params = params.append('onlytype', onlytype);
    if (excludetype) params = params.append('excludetype', excludetype);
    if (days) params = params.append('days', days.toString());
    if (norevoked !== undefined)
      params = params.append('norevoked', norevoked.toString());

    const options = { params: params };

    return this.http.get(url, options).pipe(
      // Adapt each item in the raw data array
      map((response: LicensesResponse) =>
        response.data.licenses.map(License.adapt)
      )
    );
  }

  /**
   * Gets license
   * @param lID License ID to get
   * @returns License object
   */
  get(lID: number): Observable<License> {
    const url = `${this.baseUrl}/${lID}`;
    return this.http.get(url).pipe(
      // Adapt each item in the raw data array
      map((response: LicenseResponse) => License.adapt(response.data))
      // catchError(this.handleError)
    );
  }

  /**
   * Deletes license
   * @param lID License ID to delete
   * @returns Jsend result
   */
  delete(lID: number, fullDelete = false): Observable<Object> {
    const url = `${this.baseUrl}/${lID}`;
    let params = new HttpParams();
    if (fullDelete) params = params.append('delete', fullDelete.toString());

    const options = fullDelete ? { params: params } : {};

    return this.http.delete(url, options);
  }

  /**
   * Gets licenses history
   * @param lID License ID to get history for
   * @returns array of License object
   */
  getLicenseHistory(lID: number): Observable<License[]> {
    const url = `${this.baseUrl}/${lID}/history`;
    return this.http.get(url).pipe(
      // Adapt each item in the raw data array
      map((response: LicensesResponse) =>
        response.data.licenses.map(License.adapt)
      )
      // catchError(this.handleError)
    );
  }

  /**
   * Attachs history
   * @param lID License ID of new license
   * @param oldID License ID of old license to attach to
   * @returns bolean result
   */
  attachHistory(lID: number, oldID: number): Observable<any> {
    const url = `${this.baseUrl}/${lID}/attach/${oldID}`;
    return this.http.put(url, null).pipe(
      // Adapt each item in the raw data array
      map(response => true),
      catchError(() => of(false))
    );
  }

  /**
   * Change comments of a license
   * @param lID License ID of license
   * @param newcomments Comments to replace old comments witt
   * @returns License object
   */
  changecomments(lID: number, newcomments: string): Observable<License> {
    const url = `${this.baseUrl}/${lID}/changecomments`;
    return this.http.put(url, { comments: newcomments }).pipe(
      // Adapt each item in the raw data array
      map((response: LicenseResponse) => License.adapt(response.data))
      // catchError(() => of(false))
    );
  }

  /**
   * Change data of a license
   * @param lID License ID of license
   * @param changeRequest Datato replace existing license data
   * @returns License object
   */
  change(
    lID: number,
    changeRequest: ChangeLicenseRequest
  ): Observable<License> {
    const url = `${this.baseUrl}/${lID}/change`;
    return this.http.put(url, changeRequest).pipe(
      // Adapt each item in the raw data array
      map((response: LicenseResponse) => License.adapt(response.data))
      // catchError(() => of(false))
    );
  }
}
