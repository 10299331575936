import { ResponseInterface } from './types';

export class VerifyReset {
  constructor(public isNewUser: boolean, public termsOfService: string) {}

  static adapt(item: any): VerifyReset {
    return new VerifyReset(item.isNewUser, item.termsOfService);
  }
}

export class VerifyResetData {
  constructor(public verifyReset: VerifyReset) {}
}

export class VerifyResetResponse implements ResponseInterface {
  status = '';
  data: VerifyResetData;
  constructor() {}
}
