import { ResponseInterface } from './types';

export class Application {
  constructor();
  constructor(
    applicationId: number,
    applicationGuid: string,
    name: string,
    description: string,
    comments: string,
    creationdate: Date,
    createdById: number,
    lastModifiedDate: Date,
    lastModifiedById: number,
    licenseKeyName: string,
    storageFolder: string,
    publicKey: string,
    password: string,
    hidden: boolean,
    enableDemos?: boolean,
    demoPassword?: string,
    demoIPFilter?: string,
    maxDemoDays?: number,
    currentUserRoles?: string[],
    licenseIssueBodyText?: string,
    licenseIssueSubjectText?: string,
    versionUploadBodyText?: string,
    versionUploadSubjectText?: string,
    noLicenseDownload?: boolean
  );
  constructor(
    public applicationId?: number,
    public applicationGuid?: string,
    public name?: string,
    public description?: string,
    public comments?: string,
    public creationdate?: Date,
    public createdById?: number,
    public lastModifiedDate?: Date,
    public lastModifiedById?: number,
    public licenseKeyName?: string,
    public storageFolder?: string,
    public publicKey?: string,
    public password?: string,
    public hidden?: boolean,
    public enableDemos?: boolean,
    public demoPassword?: string,
    public demoIPFilter?: string,
    public maxDemoDays?: number,
    public currentUserRoles?: string[],
    public licenseIssueBodyText?: string,
    public licenseIssueSubjectText?: string,
    public versionUploadBodyText?: string,
    public versionUploadSubjectText?: string,
    public noLicenseDownload?: boolean
  ) {}

  static adapt(item: any): Application {
    return new Application(
      item.applicationId,
      item.applicationGuid,
      item.name,
      item.description,
      item.comments,
      new Date(item.creationDate),
      item.createdById,
      new Date(item.lastModifiedDate),
      item.lastModifiedById,
      item.licenseKeyName,
      item.storageFolder,
      item.publicKey,
      item.password,
      item.hidden,
      item.enableDemos,
      item.demoPassword,
      item.demoIPFilter,
      item.maxDemoDays,
      item.currentUserRoles,
      item.licenseIssueBodyText,
      item.licenseIssueSubjectText,
      item.versionUploadBodyText,
      item.versionUploadSubjectText,
      item.noLicenseDownload
    );
  }
}

export class ApplicationsData {
  constructor(public applications: Application[]) {}
}

export class ApplicationResponse implements ResponseInterface {
  status = '';
  data: Application;
  constructor() {}
}

export class ApplicationsResponse implements ResponseInterface {
  status = '';
  data: ApplicationsData;
  constructor() {}
}
