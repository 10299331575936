import { Injectable } from '@angular/core';
import { NbRoleProvider } from '@nebular/security';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../core.module';
import { selectRoles } from './store/auth.selectors';

@Injectable()
export class RoleProvider implements NbRoleProvider {
  constructor(private store: Store<AppState>) {}

  getRole(): Observable<string> {
    return this.store.pipe(select(selectRoles));
  }
}
