import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from '../../../core/models/user.model';

@Component({
  selector: 'idl-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
  user: User;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: User) {
    if (dialogData) {
      this.user = dialogData;
    } else {
      this.user = new User();
    }
  }

  ngOnInit() {}
}
