import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CreateLicenseRequest,
  License,
  LicenseResponse
} from '../models/license.model';
import { Version, VersionsResponse } from '../models/version.model';

@Injectable({
  providedIn: 'root'
})
export class ApplicationLicenseService {
  /**
   * Base url of application license service
   */
  private baseUrl = 'api/applications';

  constructor(private http: HttpClient) {}

  /**
   * Creates an application license
   * @param appID Application ID to create license for
   * @param clr CreateLicenseRequest object defining license attributes
   * @returns The created License object
   */
  create(appID: number, clr: CreateLicenseRequest): Observable<License> {
    const url = `${this.baseUrl}/${appID}/licenses`;
    return this.http.put(url, clr).pipe(
      // Adapt each item in the raw data array
      map((response: LicenseResponse) => License.adapt(response.data))
      // catchError(this.handleError)
    );
  }

  /**
   * Gets the latest version of an application
   * If license ID is specified it will be the latestthat Id can download
   * @param appID Application to query for versions
   * @param [licenseId] License ID to check against
   * @returns Latest version object
   */
  latestVersion(appID: number, licenseId?: number): Observable<Version[]> {
    const url = `${this.baseUrl}/${appID}/latestversion`;

    let params = new HttpParams();
    if (licenseId) params = params.append('licenseId', licenseId.toString());
    const options = licenseId ? { params: params } : {};

    return this.http.get(url, options).pipe(
      // Adapt each item in the raw data array
      map((response: VersionsResponse) =>
        response.data.versions.map(Version.adapt)
      )
    );
  }
}
