import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app/app.component';
import { AvatarDirective } from './app/avatar.directive';
import { CoreModule } from './core/core.module';
import { DataModule } from './core/data/data.module';
import {
  rollbarFactory,
  RollbarService
} from './core/error-handler/rollbar.module';
import { DayjsDateModule } from './core/utils/material-dayjs-adapter/material-dayjs-adapter.module';
import { UsersModule } from './pages/users/users.module';
import { SharedModule } from './shared/shared.module';

@NgModule({
  imports: [
    // angular
    BrowserAnimationsModule,
    BrowserModule,

    // core & shared
    CoreModule,
    DataModule,
    DayjsDateModule,
    SharedModule,

    // app
    AppRoutingModule,
    UsersModule,

    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    })
  ],
  declarations: [AppComponent, AvatarDirective],
  bootstrap: [AppComponent],
  providers: [
    { provide: 'BASE_API_URL', useValue: environment.apiUrl },
    { provide: RollbarService, useFactory: rollbarFactory }
  ],
  exports: []
})
export class AppModule {}
