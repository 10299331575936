import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import {
  MetadataSet,
  MetadataSetResponse,
  MetadataSetsResponse
} from '../models/metadata-set.model';

@Injectable({
  providedIn: 'root'
})
export class MetadataSetService {
  private baseUrl = 'api/applications';

  constructor(private http: HttpClient) {}

  list(appID: number): Observable<MetadataSet[]> {
    const url = `${this.baseUrl}/${appID}/metadatasets`;
    return this.http.get(url).pipe(
      first(),
      // Adapt each item in the raw data array
      map((response: MetadataSetsResponse) =>
        response.data.metadataSets.map(MetadataSet.adapt)
      )
    );
  }

  get(appID: number, mdsID: number): Observable<MetadataSet> {
    const url = `${this.baseUrl}/${appID}/metadatasets/${mdsID}`;
    return this.http.get(url).pipe(
      // Adapt each item in the raw data array
      map((response: MetadataSetResponse) => MetadataSet.adapt(response.data))
    );
  }

  edit(appID: number, mds: MetadataSet): Observable<MetadataSet> {
    const url = `${this.baseUrl}/${appID}/metadatasets`;
    return this.http.put(url, mds).pipe(
      // Adapt each item in the raw data array
      map((response: MetadataSetResponse) => MetadataSet.adapt(response.data))
    );
  }

  delete(appID: number, mdsID: number): Observable<Object> {
    const url = `${this.baseUrl}/${appID}/metadatasets/${mdsID}`;
    return this.http.delete(url);
  }
}
