<h2 mat-dialog-title>Edit Version</h2>
<mat-dialog-content>
  <form name="editVersion">

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Name</mat-label>
      <input matInput name="name" placeholder="Filename" type="text" [(ngModel)]="version.name">
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Version</mat-label>
      <input matInput name="version" placeholder="1.0.0.0" type="text" [(ngModel)]="version.version">
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Release Date</mat-label>
      <input matInput [matDatepicker]="picker" placeholder="Release Date" name="uploadDate" [(ngModel)]="version.uploadDate">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill" class="full-width">
      <mat-label>Release Notes</mat-label>
      <textarea matInput name="comments" placeholder="Release Notes" cols="40" rows="3" [(ngModel)]="version.comments">
          </textarea>
    </mat-form-field>

    <div>
      <mat-checkbox [(ngModel)]="version.hidden" name="hide">Hide</mat-checkbox>
    </div>

    <div *nbIsGranted="['delete', 'file']">
      <button mat-stroked-button color="warn" (click)="onDelete()">Delete File</button>
    </div>

  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button status="danger" mat-dialog-close>Cancel</button>
  <button mat-button status="success" mat-dialog-close="save" cdkFocusInitial>Save</button>
</mat-dialog-actions>