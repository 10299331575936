import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { License } from '~core/models/license.model';

@Component({
  selector: 'idl-generate-license-dialog',
  template: `
    <h2 mat-dialog-title>License Details</h2>
    <mat-dialog-content class="mat-typography">
      <idl-generate-license
        #child
        [applicationId]="applicationId"
        [license]="license"
        [saveEvent]="saveSubject"
        (saved)="saved($event)"
      ></idl-generate-license>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
      <button mat-button mat-dialog-close>Cancel</button>
      <button
        mat-raised-button
        color="accent"
        [disabled]="child.generateLicenseForm.invalid || child.submitted"
        (click)="submit()"
      >
        Submit
      </button>
    </mat-dialog-actions>
  `,
  styles: [
    '.mat-dialog-content { height: 100%!important;}',
    '.dialog-button { margin: 0 5px;}'
  ]
})
export class GenerateLicenseDialogComponent {
  applicationId: number;
  license: License;

  public saveSubject: Subject<void> = new Subject<void>();
  submitted = false;

  constructor(
    private _dialogRef: MatDialogRef<GenerateLicenseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    if (dialogData) {
      this.applicationId = dialogData.applicationId;
      this.license = dialogData.license;
    }
  }

  submit() {
    this.saveSubject.next();
  }

  saved(license) {
    this._dialogRef.close(license);
  }
}
