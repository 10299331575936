import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicationsService } from '~app/core/data/applications.service';
import { ApplicationLicenseService } from '../../core/data/application-license.service';

@Component({
  selector: 'idl-license-actions',
  template: `
    <button
      mat-icon-button
      *ngIf="downloadAvailable$ | async"
      matTooltip="Download Latest"
      class="license-actions"
      (click)="downloadLatest()"
    >
      <mat-icon>cloud_download</mat-icon>
    </button>
    <button
      *ngIf="!rowData.noLicenseDownload"
      mat-icon-button
      matTooltip="Download License"
      class="license-actions"
      (click)="downloadLicense()"
    >
      <mat-icon>save_alt</mat-icon>
    </button>
    <button
      mat-icon-button
      *ngIf="downloadAvailable$ | async"
      matTooltip="More Files"
      class="license-actions"
      (click)="viewVersions()"
    >
      <mat-icon>open_in_new</mat-icon>
    </button>
  `,
  styleUrls: ['./license-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class LicenseActionsComponent implements OnInit {
  downloadAvailable$: Observable<boolean>;

  @Input() rowData: any;

  @Output() download: EventEmitter<any> = new EventEmitter();
  @Output() getlicense: EventEmitter<any> = new EventEmitter();
  @Output() versions: EventEmitter<any> = new EventEmitter();

  constructor(
    private _appLicenseService: ApplicationLicenseService,
    private _appService: ApplicationsService
  ) {}

  ngOnInit() {
    this.downloadAvailable$ = this._appLicenseService
      .latestVersion(this.rowData.applicationId, this.rowData.licenseRecordId)
      .pipe(map(versions => versions.length > 0));
  }

  downloadLatest() {
    this.download.emit(this.rowData);
  }

  downloadLicense() {
    this.getlicense.emit(this.rowData);
  }

  viewVersions() {
    this.versions.emit(this.rowData);
  }
}
