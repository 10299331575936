<form #licenseForm="ngForm" *ngIf="license">
  <div class="row">
    <div class="col-md-6">
      <div class="row">
        <div class="col-sm-6">
          <mat-form-field class="full-width">
            <mat-label>Organisation</mat-label>
            <input matInput readonly placeholder="Organisation" [(ngModel)]="license.organisationName"
              name="organisationName">
          </mat-form-field>
        </div>

        <div class="col-sm-6">
          <mat-form-field class="full-width">
            <mat-label>License Holder Name</mat-label>
            <input matInput readonly placeholder="License Holder Name" [(ngModel)]="license.licenseHolderName"
              name="licenseHolderName">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <mat-form-field class="full-width">
            <mat-label>Application</mat-label>
            <input matInput readonly placeholder="Application" [(ngModel)]="license.applicationName"
              name="applicationName">
          </mat-form-field>
        </div>
        <div class="col-sm-6">
          <mat-form-field class="full-width" floatLabel="always">
            <mat-label>Metadata Set</mat-label>
            <input matInput readonly [(ngModel)]="license.metadataSetName" name="metadataSetName">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <mat-form-field class="full-width">
            <mat-label>Type</mat-label>
            <input matInput readonly placeholder="Type" [(ngModel)]="license.type" name="type">
          </mat-form-field>
        </div>
        <div class="col-sm-6">
          <mat-form-field class="full-width">
            <mat-label>Expiry Date</mat-label>
            <input matInput readonly placeholder="Expiry Date" [(ngModel)]="license.expirydate" name="expirydate">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <mat-form-field class="full-width">
            <mat-label>Created By</mat-label>
            <input matInput readonly placeholder="Created By" [(ngModel)]="license.createdByUserName"
              name="createdByUserName">
          </mat-form-field>
        </div>
        <div class="col-sm-6">
          <mat-form-field class="full-width">
            <mat-label>Creation Date</mat-label>
            <input matInput readonly placeholder="Creation Date" [(ngModel)]="license.creationdate" name="creationdate">
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <mat-form-field class="full-width">
        <mat-label>Comments</mat-label>
        <textarea rows="12" matInput readonly placeholder="Comments" [(ngModel)]="license.comments"
          name="application"></textarea>
      </mat-form-field>
    </div>
  </div>
</form>

<div class="table-container">
  <mat-table *ngIf="!isLoading" [dataSource]="dataSource" matSort>
    <!-- Appication Column -->
    <ng-container matColumnDef="applicationName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Application</mat-header-cell>
      <mat-cell fxLayout="column" fxLayoutAlign="space-around start" *matCellDef="let license">
        <span class="mobile-label">Application:</span>
        <!-- <idl-license-appname *ngIf="myLicenses; else appNameElse" [rowData]="license"></idl-license-appname> -->
        <ng-template #appNameElse>{{license.applicationName}}</ng-template>

        <!-- <div class="row-actions">
          <button (click)="onDetailsClick(license)">View Details</button> |
          <button (click)="onHistoryClick(license)">View History</button>
        </div> -->
      </mat-cell>
    </ng-container>

    <!-- Metadata Column -->
    <ng-container matColumnDef="metadataSetName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Customisation</mat-header-cell>
      <mat-cell *matCellDef="let license">
        {{license.metadataSetName}}
      </mat-cell>
    </ng-container>

    <!-- License Holder Column -->
    <ng-container matColumnDef="licenseHolderName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>License Holder</mat-header-cell>
      <mat-cell *matCellDef="let license">
        {{license.licenseHolderName}}
      </mat-cell>
    </ng-container>

    <!-- Organisation Column -->
    <ng-container matColumnDef="organisationName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Organisation </mat-header-cell>
      <mat-cell *matCellDef="let license">
        {{license.organisationName}}
      </mat-cell>
    </ng-container>

    <!-- Industry Column -->
    <ng-container matColumnDef="industries">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Industry </mat-header-cell>
      <mat-cell *matCellDef="let license">
        <mat-chip-list *ngIf="license.industries" aria-label="Industries">
          <mat-chip *ngFor="let industry of license.industries">
            {{industry.name}}
          </mat-chip>
        </mat-chip-list>
      </mat-cell>
    </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
      <mat-cell *matCellDef="let license">
        {{license.type}}
      </mat-cell>
    </ng-container>

    <!-- Expiry Date Column -->
    <ng-container matColumnDef="expirydate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Expiry Date</mat-header-cell>
      <mat-cell *matCellDef="let license">
        <div *ngIf="license.type != 'Perpetual'">
          {{license.expirydate|date:'mediumDate'}}
        </div>
      </mat-cell>
    </ng-container>

    <!-- Creation Date Column -->
    <ng-container matColumnDef="creationdate">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Creation Date</mat-header-cell>
      <mat-cell *matCellDef="let license">
        {{license.creationdate|date:'mediumDate'}}
      </mat-cell>
    </ng-container>

    <!-- Created Column -->
    <ng-container matColumnDef="createdByUserName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Created By</mat-header-cell>
      <mat-cell *matCellDef="let license">
        <div>
          <div class="name">{{license.createdByUserName}}</div>
          <div class="creationdate">Date: {{license.creationdate|date:'mediumDate'}}</div>
        </div>
      </mat-cell>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="revokeButton">
      <mat-header-cell *matHeaderCellDef>Revoke</mat-header-cell>
      <mat-cell *matCellDef="let license">
        <div *ngIf="!license.revoked && license.nextId">
          <button *nbIsGranted="['revoke', 'license']" mat-raised-button color="warn" (click)="onRevoke(license)">
            Revoke
          </button>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <!-- <ng-container *ngIf="myLicenses; else rowElseBlock">
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </ng-container>
    <ng-template #rowElseBlock> -->
      <mat-row *matRowDef="let row; columns: displayedColumns;"
        [ngClass]="{'row-highlight': selectedRowIndex == row.licenseGuid}" (click)="rowSelected(row)"></mat-row>
    <!-- </ng-template> -->

  </mat-table>
</div>

<mat-spinner *ngIf="isLoading"></mat-spinner>

<mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons></mat-paginator>