import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  Metadata,
  MetadataResponse,
  MetadatasResponse
} from '../models/metadata.model';

@Injectable({
  providedIn: 'root'
})
export class MetadataService {
  private baseUrl = 'api/applications';

  constructor(private http: HttpClient) {}

  list(appID: number, mdsID: number): Observable<Metadata[]> {
    const url = `${this.baseUrl}/${appID}/metadatasets/${mdsID}/metadata`;
    return this.http.get(url).pipe(
      // Adapt each item in the raw data array
      map((response: MetadatasResponse) =>
        response.data.metadata.map(Metadata.adapt)
      )
    );
  }

  edit(appID: number, md: Metadata): Observable<Metadata> {
    const url = `${this.baseUrl}/${appID}/metadata`;
    return this.http.put(url, md).pipe(
      // Adapt each item in the raw data array
      map((response: MetadataResponse) => Metadata.adapt(response.data))
    );
  }
}
