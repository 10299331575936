import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment as env } from '@app/../environments/environment';
import { AuthService } from '@auth0/auth0-angular';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { State } from '~core/auth/models/auth-state.model';
import { selectAuthState } from '~core/core.state';
import { LocalStorageService } from '~core/local-storage/local-storage.service';
import { LogoutPromptComponent } from '../../components/logout-prompt.component';
import * as fromAuth from '../actions/auth.actions';

export const AUTH_KEY = 'AUTH';

// TODO: Romove storage service stuff
@Injectable()
export class AuthEffects {
  login$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromAuth.Login>(fromAuth.AuthActionTypes.Login),
        tap(action => {
          return this._auth.loginWithRedirect();
        })
      ),
    { dispatch: false }
  );

  @Effect()
  loginComplete$ = this.actions$.pipe(
    ofType<fromAuth.LoginComplete>(fromAuth.AuthActionTypes.LoginComplete),
    map(action => {
      if (action.loggedin) {
        return new fromAuth.LoginSuccess({
          targetroute: action.targetroute,
          user: action.user,
          id: action.id,
          roles: action.roles
        });
      } else {
        return EMPTY;
      }
    })
  );

  @Effect({ dispatch: false })
  loginRedirect$ = this.actions$.pipe(
    ofType<fromAuth.LoginSuccess>(fromAuth.AuthActionTypes.LoginSuccess),
    map(action => action.payload),
    withLatestFrom(this._store.pipe(select(selectAuthState))),
    tap(([payload, auth]) => {
      this._localStorageService.setItem(AUTH_KEY, auth);
      if (payload.targetroute) this.router.navigate([payload.targetroute]);
    })
  );

  @Effect({ dispatch: false })
  loginErrorRedirect$ = this.actions$.pipe(
    ofType<fromAuth.LoginFailure>(fromAuth.AuthActionTypes.LoginFailure),
    map(action => action.payload),
    tap((err: any) => {
      // console.log('Login Error redirect');
      if (err.error_description) {
        console.error(`Error: ${err.error_description}`);
      } else {
        console.error(`Error: ${JSON.stringify(err)}`);
      }
    })
  );

  @Effect({ dispatch: false })
  checkLogin$ = this.actions$.pipe(
    ofType<fromAuth.CheckLogin>(fromAuth.AuthActionTypes.CheckLogin),
    tap(() => {
      return this._auth.getAccessTokenSilently().pipe(
        map(token => {
          return new fromAuth.LoginSuccess(null);
        }),
        catchError(error => {
          return of(new fromAuth.LoginFailure({ error }));
        })
      );
    })
  );

  @Effect()
  refreshComplete$ = this.actions$.pipe(
    ofType<fromAuth.RefreshComplete>(fromAuth.AuthActionTypes.RefreshComplete),
    map(action => {
      if (action.loggedin) {
        return new fromAuth.LoginSuccess({
          targetroute: null,
          user: action.user,
          id: action.id,
          roles: action.roles
        });
      } else {
        return EMPTY;
      }
    })
  );

  @Effect({ dispatch: false })
  refreshLogin$ = this.actions$.pipe(
    ofType<fromAuth.RefreshLogin>(fromAuth.AuthActionTypes.RefreshLogin),
    tap(() => {
      return this._auth.getAccessTokenSilently();
    })
  );

  @Effect()
  logoutConfirmation$ = this.actions$.pipe(
    ofType<fromAuth.Logout>(fromAuth.AuthActionTypes.Logout),
    exhaustMap(() =>
      this.dialogService
        .open(LogoutPromptComponent)
        .afterClosed()
        .pipe(
          map(confirmed => {
            if (confirmed) {
              return new fromAuth.LogoutConfirmed();
            } else {
              return new fromAuth.LogoutCancelled();
            }
          })
        )
    )
  );

  @Effect({ dispatch: false })
  logout$ = this.actions$.pipe(
    ofType<fromAuth.LogoutConfirmed>(fromAuth.AuthActionTypes.LogoutConfirmed),
    withLatestFrom(this._store.pipe(select(selectAuthState))),
    tap(([payload, auth]) => {
      this._localStorageService.setItem(AUTH_KEY, auth);
      this._auth.logout({
        client_id: env.auth.clientId,
        returnTo: `${window.location.origin}`
      });
    })
  );

  constructor(
    private actions$: Actions,
    private _auth: AuthService,
    private _localStorageService: LocalStorageService,
    private router: Router,
    private _store: Store<State>,
    private dialogService: MatDialog
  ) {}
}
