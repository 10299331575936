import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  User,
  UserResponse,
  UsersResponse,
  UserRolesResponse,
  UserRoles
} from '../models/user.model';
import { SetPassword } from '../models/set-password.model';
import {
  UserSummary,
  UsersSummaryResponse,
  UserSummaryResponse
} from '../models/user-summary.model';
// import { tap } from 'rxjs/operators';
import {
  AppUserSettings,
  AppUserSettingsResponse
} from '../models/app-user-settings.model';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private baseUrl = 'api/users';

  constructor(private http: HttpClient) {}

  list(
    appId?: number,
    role?: string,
    organisationid?: number,
    hidelockedout?: boolean,
    summary?: boolean
  ): Observable<any[]> {
    const url = `${this.baseUrl}`;
    let params = new HttpParams();
    if (appId) params = params.append('appid', appId.toString());
    if (role) params = params.append('role', role);
    if (organisationid)
      params = params.append('organisationid', organisationid.toString());
    if (hidelockedout)
      params = params.append('hidelockedout', String(hidelockedout));
    if (summary) params = params.append('summary', String(summary));
    const options =
      appId || role || organisationid || hidelockedout || summary
        ? { params: params }
        : {};

    if (summary) {
      return this.http.get(url, options).pipe(
        // Adapt each item in the raw data array
        map((response: UsersSummaryResponse) =>
          response.data.users.map(UserSummary.adapt)
        )
        // catchError(this.handleError)
      );
    } else {
      return this.http.get(url, options).pipe(
        // Adapt each item in the raw data array
        map((response: UsersResponse) =>
          response.data.users.map(User.adaptUserList)
        )
        // catchError(this.handleError)
      );
    }
  }

  get(userId: number): Observable<User> {
    const url = `${this.baseUrl}/${userId}`;
    return this.http.get(url).pipe(
      // tap(user => console.log(user)),
      // Adapt each item in the raw data array
      map((response: UserResponse) => User.adaptUser(response.data))
      // catchError(this.handleError)
    );
  }

  getUserSummary(userId: number): Observable<UserSummary> {
    const url = `${this.baseUrl}/${userId}`;
    return this.http.get(url).pipe(
      // tap(user => console.log(user)),
      // Adapt each item in the raw data array
      map((response: UserSummaryResponse) => UserSummary.adapt(response.data))
      // catchError(this.handleError)
    );
  }

  listroles(userId: number, appId?: number): Observable<string[]> {
    const url = `${this.baseUrl}/${userId}/roles`;
    let params = new HttpParams();
    if (appId) params = params.append('appId', appId.toString());
    const options = appId ? { params: params } : {};

    return this.http.get(url, options).pipe(
      // Adapt each item in the raw data array
      map((response: UserRolesResponse) => UserRoles.adapt(response.data).roles)
      // catchError(this.handleError)
    );
  }

  edit(user: User): Observable<User> {
    const url = `${this.baseUrl}`;
    return this.http.put(url, user).pipe(
      // Adapt each item in the raw data array
      map((response: UserResponse) => User.adaptUser(response.data))
      // catchError(this.handleError)
    );
  }

  addrole(userId: number, role: string, appId?: number): Observable<string[]> {
    const url = `${this.baseUrl}/${userId}/roles`;
    let params = new HttpParams();
    if (appId) params = params.append('appId', appId.toString());

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.put(url, `{ "role": "${role}" }`, options).pipe(
      // Adapt each item in the raw data array
      map((response: UserRolesResponse) => UserRoles.adapt(response.data).roles)
      // catchError(this.handleError)
    );
  }

  deleterole(
    userId: number,
    role: string,
    appId?: number
  ): Observable<string[]> {
    const url = `${this.baseUrl}/${userId}/roles`;
    let params = new HttpParams();
    params = params.append('role', role);
    if (appId) params = params.append('appId', appId.toString());
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params: params
    };
    return this.http.delete(url, options).pipe(
      // Adapt each item in the raw data array
      map((response: UserRolesResponse) => UserRoles.adapt(response.data).roles)
      // catchError(this.handleError)
    );
  }

  setpassword(password: string): Observable<Object> {
    const url = `${this.baseUrl}/setpassword`;
    const request = new SetPassword(null, null, password, true);
    return this.http.put(url, request);
  }

  editAppSettings(userId: number, settings: any): Observable<User> {
    const url = `${this.baseUrl}/${userId}/appsettings`;
    return this.http.put(url, settings).pipe(
      // Adapt each item in the raw data array
      map((response: AppUserSettingsResponse) =>
        AppUserSettings.adapt(response.data)
      )
      // catchError(this.handleError)
    );
  }
}
